import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import Escopo from '../../../images/escopo2022/image.jpg';

const PapelDistribuidor: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img={Escopo} title='PAPEL DISTRIBUIDOR X ATACADO' />
		</>
	);
};

export default PapelDistribuidor;
