import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useStyles } from 'components/TitlePicturePage/styles';
import { FC, useState } from 'react';
import RTM2 from '../../../images/apn2022/05_analise_individual/Slide10.jpg';
import RTM3 from '../../../images/apn2022/05_analise_individual/Slide11.jpg';
import RTM4 from '../../../images/apn2022/05_analise_individual/Slide12.jpg';
import RTM5 from '../../../images/apn2022/05_analise_individual/Slide13.jpg';
import RTM6 from '../../../images/apn2022/05_analise_individual/Slide14.jpg';
import RTM7 from '../../../images/apn2022/05_analise_individual/Slide15.jpg';
import RTM8 from '../../../images/apn2022/05_analise_individual/Slide16.jpg';
import RTM9 from '../../../images/apn2022/05_analise_individual/Slide17.jpg';
import RTM10 from '../../../images/apn2022/05_analise_individual/Slide18.jpg';
import RTM11 from '../../../images/apn2022/05_analise_individual/Slide19.jpg';
import RTM12 from '../../../images/apn2022/05_analise_individual/Slide20.jpg';
import RTM13 from '../../../images/apn2022/05_analise_individual/Slide21.jpg';
import RTM14 from '../../../images/apn2022/05_analise_individual/Slide22.jpg';
import RTM15 from '../../../images/apn2022/05_analise_individual/Slide23.jpg';
import RTM16 from '../../../images/apn2022/05_analise_individual/Slide24.jpg';
import RTM17 from '../../../images/apn2022/05_analise_individual/Slide25.jpg';
import RTM18 from '../../../images/apn2022/05_analise_individual/Slide26.jpg';
import RTM19 from '../../../images/apn2022/05_analise_individual/Slide27.jpg';
import RTM20 from '../../../images/apn2022/05_analise_individual/Slide28.jpg';
import RTM21 from '../../../images/apn2022/05_analise_individual/Slide29.jpg';
import RTM22 from '../../../images/apn2022/05_analise_individual/Slide30.jpg';
import RTM23 from '../../../images/apn2022/05_analise_individual/Slide31.jpg';
import RTM24 from '../../../images/apn2022/05_analise_individual/Slide32.jpg';
import RTM25 from '../../../images/apn2022/05_analise_individual/Slide33.jpg';
import RTM26 from '../../../images/apn2022/05_analise_individual/Slide34.jpg';
import RTM27 from '../../../images/apn2022/05_analise_individual/Slide35.jpg';
import RTM28 from '../../../images/apn2022/05_analise_individual/Slide36.jpg';
import RTM29 from '../../../images/apn2022/05_analise_individual/Slide37.jpg';
import RTM30 from '../../../images/apn2022/05_analise_individual/Slide38.jpg';
import RTM31 from '../../../images/apn2022/05_analise_individual/Slide39.jpg';
import RTM32 from '../../../images/apn2022/05_analise_individual/Slide40.jpg';
import RTM33 from '../../../images/apn2022/05_analise_individual/Slide41.jpg';
import RTM34 from '../../../images/apn2022/05_analise_individual/Slide42.jpg';
import RTM35 from '../../../images/apn2022/05_analise_individual/Slide43.jpg';
import RTM36 from '../../../images/apn2022/05_analise_individual/Slide44.jpg';
import RTM37 from '../../../images/apn2022/05_analise_individual/Slide45.jpg';
import RTM38 from '../../../images/apn2022/05_analise_individual/Slide46.jpg';
import RTM39 from '../../../images/apn2022/05_analise_individual/Slide47.jpg';
import RTM40 from '../../../images/apn2022/05_analise_individual/Slide48.jpg';
import RTM1 from '../../../images/apn2022/05_analise_individual/Slide9.jpg';
const AnalisisIndiv: FC = () => {
	const classes = useStyles();
	const [selection, setselection] = useState('ASA');
	const handleChangeSelect = (event: SelectChangeEvent) => {
		setselection(event.target.value as string);
	};
	const handleImage = () => {
		switch (selection) {
			case 'ASA':
				return <img src={RTM1} alt='img' />;
			case 'ASA BRANCA':
				return <img src={RTM2} alt='img' />;
			case 'AVANCO DISTRIBUIÇÃO E LOGÍSTICA':
				return <img src={RTM3} alt='img' />;
			case 'BATISTA COMERCIAL E REPRESENTA':
				return <img src={RTM4} alt='img' />;
			case 'CARDEAL':
				return <img src={RTM5} alt='img' />;
			case 'CBN DISTRIBUIDOR ALIMENTAR':
				return <img src={RTM6} alt='img' />;
			case 'CDA - CENTRAL DE DISTRIBUIÇÃO':
				return <img src={RTM7} alt='img' />;
			case 'COMERCIAL ALIMENTÍCIA PULMER L':
				return <img src={RTM8} alt='img' />;
			case 'COMPARE':
				return <img src={RTM9} alt='img' />;
			case 'DAC':
				return <img src={RTM10} alt='img' />;
			case 'DESTAQUE DO NORTE':
				return <img src={RTM11} alt='img' />;
			case 'DISDAL DF':
				return <img src={RTM12} alt='img' />;
			case 'DISDAL GO':
				return <img src={RTM13} alt='img' />;
			case 'DISDAL RO':
				return <img src={RTM14} alt='img' />;
			case 'DISFER DISTRIBUIDORA DE PRODUT':
				return <img src={RTM15} alt='img' />;
			case 'DISJOI':
				return <img src={RTM16} alt='img' />;
			case 'DISO':
				return <img src={RTM17} alt='img' />;
			case 'DISTRIBUIDORA OK':
				return <img src={RTM18} alt='img' />;
			case 'DN DISTRIBUIDORA':
				return <img src={RTM19} alt='img' />;
			case 'DUNORTE DISTRIBUIDORA':
				return <img src={RTM20} alt='img' />;
			case 'FOCUS DISTRIBUIDORA':
				return <img src={RTM21} alt='img' />;
			case 'GALERA':
				return <img src={RTM22} alt='img' />;
			case 'GERIBA DISTRIBUIDORA LTDA':
				return <img src={RTM23} alt='img' />;
			case 'IBIAPINA CE':
				return <img src={RTM24} alt='img' />;
			case 'IBIAPINA PI':
				return <img src={RTM25} alt='img' />;
			case 'INBAHIA REPRESENTAÇÕES E DISTR.':
				return <img src={RTM26} alt='img' />;
			case 'J. P. FIGUEIRA':
				return <img src={RTM27} alt='img' />;
			case 'LATIFRIOS':
				return <img src={RTM28} alt='img' />;
			case 'MEGA RIO':
				return <img src={RTM29} alt='img' />;
			case 'META DISTRIBUIDOR LTDA':
				return <img src={RTM30} alt='img' />;
			case 'NILO TOZZO E CIA':
				return <img src={RTM31} alt='img' />;
			case 'OKAJIMA':
				return <img src={RTM32} alt='img' />;
			case 'PLANESE':
				return <img src={RTM33} alt='img' />;
			case 'PACALUZ COMERCIO E LOGISTICA L':
				return <img src={RTM34} alt='img' />;
			case 'POLYBALAS':
				return <img src={RTM35} alt='img' />;
			case 'REDE BIZ SERVIÇOS E DISTRIBUIC':
				return <img src={RTM36} alt='img' />;
			case 'SAMAVI DISTRIBUICAO E TRANSPOR':
				return <img src={RTM37} alt='img' />;
			case 'TAPAJOS COMERCIO DE PERFUMARIA MAPA':
				return <img src={RTM38} alt='img' />;
			case 'TRIUNFANTE BRASIL':
				return <img src={RTM39} alt='img' />;
			case 'TRIUNFANTE MS':
				return <img src={RTM40} alt='img' />;
			default:
				return <></>;
		}
	};
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>ANÁLISE POR DISTRIBUIDOR</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.wrapperCenter} style={{ padding: '2rem' }}>
				<Select value={selection} onChange={handleChangeSelect}>
					<MenuItem value={'ASA'}>ASA</MenuItem>
					<MenuItem value={'ASA BRANCA'}>ASA BRANCA</MenuItem>
					<MenuItem value={'AVANCO DISTRIBUIÇÃO E LOGÍSTICA'}>AVANCO DISTRIBUIÇÃO E LOGÍSTICA</MenuItem>
					<MenuItem value={'BATISTA COMERCIAL E REPRESENTA'}>BATISTA COMERCIAL E REPRESENTA</MenuItem>
					<MenuItem value={'CARDEAL'}>CARDEAL</MenuItem>
					<MenuItem value={'CBN DISTRIBUIDOR ALIMENTAR'}>CBN DISTRIBUIDOR ALIMENTAR</MenuItem>
					<MenuItem value={'CDA - CENTRAL DE DISTRIBUIÇÃO'}>CDA - CENTRAL DE DISTRIBUIÇÃO</MenuItem>
					<MenuItem value={'COMERCIAL ALIMENTÍCIA PULMER L'}>COMERCIAL ALIMENTÍCIA PULMER L</MenuItem>
					<MenuItem value={'COMPARE'}>COMPARE</MenuItem>
					<MenuItem value={'DAC'}>DAC</MenuItem>
					<MenuItem value={'DESTAQUE DO NORTE'}>DESTAQUE DO NORTE</MenuItem>
					<MenuItem value={'DISDAL DF'}>DISDAL DF</MenuItem>
					<MenuItem value={'DISDAL GO'}>DISDAL GO</MenuItem>
					<MenuItem value={'DISDAL RO'}>DISDAL RO</MenuItem>
					<MenuItem value={'DISFER DISTRIBUIDORA DE PRODUT'}>DISFER DISTRIBUIDORA DE PRODUT</MenuItem>
					<MenuItem value={'DISJOI'}>DISJOI</MenuItem>
					<MenuItem value={'DISO'}>DISO</MenuItem>
					<MenuItem value={'DISTRIBUIDORA OK'}>DISTRIBUIDORA OK</MenuItem>
					<MenuItem value={'DN DISTRIBUIDORA'}>DN DISTRIBUIDORA</MenuItem>
					<MenuItem value={'DUNORTE DISTRIBUIDORA'}>DUNORTE DISTRIBUIDORA</MenuItem>
					<MenuItem value={'FOCUS DISTRIBUIDORA'}>FOCUS DISTRIBUIDORA</MenuItem>
					<MenuItem value={'GALERA'}>GALERA</MenuItem>
					<MenuItem value={'GERIBA DISTRIBUIDORA LTDA'}>GERIBA DISTRIBUIDORA LTDA</MenuItem>
					<MenuItem value={'IBIAPINA CE'}>IBIAPINA CE</MenuItem>
					<MenuItem value={'IBIAPINA PI'}>IBIAPINA PI</MenuItem>
					<MenuItem value={'INBAHIA REPRESENTAÇÕES E DISTR.'}>INBAHIA REPRESENTAÇÕES E DISTR.</MenuItem>
					<MenuItem value={'J. P. FIGUEIRA'}>J. P. FIGUEIRA</MenuItem>
					<MenuItem value={'LATIFRIOS'}>LATIFRIOS</MenuItem>
					<MenuItem value={'MEGA RIO'}>MEGA RIO</MenuItem>
					<MenuItem value={'META DISTRIBUIDOR LTDA'}>META DISTRIBUIDOR LTDA</MenuItem>
					<MenuItem value={'NILO TOZZO E CIA'}>NILO TOZZO E CIA</MenuItem>
					<MenuItem value={'OKAJIMA'}>OKAJIMA</MenuItem>
					<MenuItem value={'PLANESE'}>PLANESE</MenuItem>
					<MenuItem value={'PACALUZ COMERCIO E LOGISTICA L'}>PACALUZ COMERCIO E LOGISTICA L</MenuItem>
					<MenuItem value={'POLYBALAS'}>POLYBALAS</MenuItem>
					<MenuItem value={'REDE BIZ SERVIÇOS E DISTRIBUIC'}>REDE BIZ SERVIÇOS E DISTRIBUIC</MenuItem>
					<MenuItem value={'SAMAVI DISTRIBUICAO E TRANSPOR'}>SAMAVI DISTRIBUICAO E TRANSPOR</MenuItem>
					<MenuItem value={'TAPAJOS COMERCIO DE PERFUMARIA MAPA'}>TAPAJOS COMERCIO DE PERFUMARIA MAPA</MenuItem>
					<MenuItem value={'TRIUNFANTE BRASIL'}>TRIUNFANTE BRASIL</MenuItem>
					<MenuItem value={'TRIUNFANTE MS'}>TRIUNFANTE MS</MenuItem>
				</Select>
			</div>
			<div className={classes.content}>{handleImage()}</div>
		</div>
	);
};

export default AnalisisIndiv;
