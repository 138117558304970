import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import img1 from '../../../images/restante/logistica/image-1.jpg';
import img2 from '../../../images/restante/logistica/image-2.jpg';
import img3 from '../../../images/restante/logistica/image-3.jpg';

const Logistica: FC = () => {
	const img_arr = [img1, img2, img3];
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img_arr={img_arr} title='LOGÍSTICA' />
		</>
	);
};

export default Logistica;
