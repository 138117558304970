import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import img from '../../../images/restante/rotina-rca/image.jpg';

const RotinaRCA: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img={img} title='ROTINA RCA' />
		</>
	);
};

export default RotinaRCA;
