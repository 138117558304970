import { FC } from 'react';
import { useStyles } from './styles';

interface ITitlePicturePage {
	colorTitle: string;
	title: string;
	img?: string;
	img_arr?: string[];
	useMap?: string;
}

const TitlePicturePage: FC<ITitlePicturePage> = ({ colorTitle, img, img_arr, title, useMap }) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: colorTitle }}>
				<p className={classes.title}>{title}</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.imgContainer}>
				{img_arr && img_arr.length > 0 ? (
					img_arr.map((value) => <img src={value} alt='alt' useMap={useMap} />)
				) : (
					<img src={img} alt='alt' useMap={useMap} />
				)}
			</div>
		</>
	);
};

export default TitlePicturePage;
