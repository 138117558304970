import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
	row: {
		display: 'flex',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	titleBar: {
		padding: 1,
	},
	title: {
		color: '#fff',
		margin: '0.5rem',
		textAlign: 'center',
		fontSize: '1.67rem',
	},
	separator: {
		backgroundColor: '#fff',
		margin: '1rem auto 1.5rem auto',
		width: 60,
		height: 4,
	},
	content: {
		padding: '2rem',
	},
	wrapperCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
	imgContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));
