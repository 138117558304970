import { IRoute } from 'interfaces';
import Private from 'router/routes/Private';
import Public from 'router/routes/Public';

export const isPrivate = () => {
	const is = Private.findIndex((val: IRoute) => {
		return val.path === window.location.pathname;
	});
	return is !== -1;
};

export const isPublic = () => {
	const is = Public.findIndex((val: IRoute) => {
		return val.path === window.location.pathname;
	});
	return is !== -1;
};
