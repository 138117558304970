import { useStyles } from 'components/TitlePicturePage/styles';
import { FC } from 'react';
import RTM1 from '../../../images/apn2022/02_ranking_brasil/Slide5.jpg';
const RankBrasil: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>RANKING BRASIL</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img' />
			</div>
		</>
	);
};

export default RankBrasil;
