import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const Params = lazy(() => import('./Params'));

const LazyParams = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Params />
		</Suspense>
	);
};

export default LazyParams;
