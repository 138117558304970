import SaveIcon from '@mui/icons-material/Save';
import { Button, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { handleError, handleSuccess } from 'components/Alert';
import { sxButtonNormal } from 'pages/GestLojas/styles';
import { FC, useLayoutEffect, useState } from 'react';
import TitlewithTable from './components/TitlewithTable';

export interface ITabla {
	id: number;
	distribuidor: string;
	area: string;
	reqNoCumplido: string;
	ptoMax: number;
	ptoAdq: number;
	acao: any | undefined;
	plazo: string | Date | undefined;
	status: string | undefined;
	action: boolean;
}

const rowsExample: ITabla[] = [
	{
		id: 0,
		distribuidor: 'ASA',
		ptoMax: 0.5,
		area: 'COMPETÊNCIAS DE RH',
		reqNoCumplido: 'DISTRIBUI PPR?',
		ptoAdq: 0.7,
		acao: undefined,
		plazo: undefined,
		status: undefined,
		action: false,
	},
	{
		id: 1,
		distribuidor: 'AVANCO DISTRIBUICAO E LOGISTIC',
		area: 'COMPETÊNCIAS LOGÍSTICAS',
		ptoMax: 1,
		reqNoCumplido: 'QUAL O LEADTIME MÁXIMO DE ENTREGA? (EM DIAS)',
		ptoAdq: 0,
		acao: undefined,
		plazo: undefined,
		status: undefined,
		action: false,
	},
];

const Oportunidades2022: FC = () => {
	const [loading, setloading] = useState(true);
	const [rows, setrows] = useState<ITabla[]>([]);

	//dataRow
	const handleChangeacao = (value: string, index: number) => {
		setrows((prev: ITabla[]) => {
			const arr = prev.map((item: ITabla, i: number) => {
				if (i === index) {
					return {
						...item,
						acao: value,
					};
				}
				return item;
			});
			return arr;
		});
	};

	const handleChangePlazo = (value: Date | undefined, index: number) => {
		setrows((prev: ITabla[]) => {
			const arr = prev.map((item: ITabla, i: number) => {
				if (i === index) {
					return {
						...item,
						plazo: value?.toLocaleString(),
					};
				}
				return item;
			});
			return arr;
		});
	};

	const handleChangeStatus = (value: string, index: number) => {
		console.log('value', value);
		setrows((prev: ITabla[]) => {
			const arr = prev.map((item: ITabla, i: number) => {
				if (i === index) {
					return {
						...item,
						status: value,
					};
				}
				return item;
			});
			return arr;
		});
	};

	const handleSave = (index: number) => {
		try {
			setloading(true);
			setTimeout(() => {
				setrows((prev: ITabla[]) => {
					const arr = prev.map((item: ITabla, i: number) => {
						if (i === index) {
							return {
								...item,
								action: true,
							};
						}
						return item;
					});
					return arr;
				});
				handleSuccess('Exito', 'cargado');
				setloading(false);
			}, 1000);
		} catch (error) {
			handleError(error);
		}
	};

	//format
	const columns: GridColDef[] = [
		{
			field: 'distribuidor',
			headerName: 'Distribuidor',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 200,
		},
		{
			field: 'area',
			headerName: 'Área de Oportunidade',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 200,
		},
		{
			field: 'reqNoCumplido',
			headerName: 'Requisito Não Cumprido',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			width: 250,
			flex: 1,
		},
		{
			field: 'ptoMax',
			headerName: 'Pontuação Máxima',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			width: 150,
		},
		{
			field: 'ptoAdq',
			headerName: 'Pontuação Adquirida',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 150,
		},
		{
			field: 'acao',
			headerName: 'Ação',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			width: 200,
			renderCell: (params: GridRenderCellParams<ITabla>) => {
				const { acao, action } = params.row;
				if (!action)
					return (
						<TextField
							size='small'
							variant='standard'
							fullWidth
							onKeyDown={(event) => {
								if (event.key === ' ') {
									event.stopPropagation();
								}
							}}
							onChange={(e) => handleChangeacao(e.target.value, Number(params.id))}
						/>
					);
				return acao;
			},
		},
		{
			field: 'plazo',
			headerName: 'Prazo',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 190,
			renderCell: (params: GridRenderCellParams) => {
				const { plazo, action } = params.row;
				if (!action)
					return (
						<DatePicker
							onChange={(value: Date | null) => handleChangePlazo(value ? value : undefined, Number(params.id))}
						/>
					);
				return plazo;
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			width: 200,
			renderCell: (params: GridRenderCellParams) => {
				const { status, action } = params.row;
				if (!action)
					return (
						<FormControl>
							<Select
								value={status ? status : ''}
								onChange={(e: SelectChangeEvent<string>) => handleChangeStatus(e.target.value, Number(params.id))}
								variant='standard'
								fullWidth>
								<MenuItem value='Em Planejamento'>Em Planejamento</MenuItem>
								<MenuItem value='Implementado'>Implementado</MenuItem>
								<MenuItem value='Não Implementado'>Não Implementado</MenuItem>
							</Select>
						</FormControl>
					);
				return status;
			},
		},
		{
			field: 'action',
			headerName: '',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 80,
			renderCell: (params: GridRenderCellParams<ITabla>) => {
				const { action } = params.row;
				const index = Number(params.id);
				if (action) {
					return null;
				}
				return (
					<Button variant='outlined' onClick={() => handleSave(index)} sx={sxButtonNormal}>
						<SaveIcon />
					</Button>
				);
			},
		},
	];

	useLayoutEffect(() => {
		let fetched = false;
		//Traer elementos desde la api y asignarlos a row
		Promise.resolve().then(() => {
			if (!fetched) {
				console.log('ya cargo');
				setloading(false);
				setrows(rowsExample);
			}
		});
		return () => {
			fetched = true;
		};
	}, []);

	return (
		<>
			<TitlewithTable loading={loading} columns={columns} rows={rows} title='PLANO DE AÇÃO - OPORTUNIDADES 2022' />
		</>
	);
};

export default Oportunidades2022;
