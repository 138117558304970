import FileUploadIcon from '@mui/icons-material/FileUpload';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TuneIcon from '@mui/icons-material/Tune';
import { ILink } from 'interfaces';
import * as url from 'router/routes/Urls';

export interface IMenuItems {
	url: string;
	name: string;
	admin?: boolean;
}

export const auxLink: ILink[] = [
	{
		name: 'Home',
		link: url.home,
		icon: <HomeIcon />,
	},
	{
		name: 'Carregamento de arquivos',
		link: url.loadFile,
		icon: <FileUploadIcon />,
	},
	{
		name: 'Gestão Lojas',
		link: url.gestLojas,
		icon: <StorefrontIcon />,
	},
	{
		name: 'Administração Lojas',
		link: url.adminLojasUrl,
		icon: <FolderSharedIcon />,
	},
	{
		name: 'Gestão Usuarios',
		link: url.gestUsuariosUrl,
		icon: <GroupIcon />,
	},
	{
		name: 'Parâmetros',
		link: url.paramsUrl,
		icon: <TuneIcon />,
	},
	{
		name: 'Upload Dados',
		link: url.dadosUrl,
		icon: <FileUploadIcon />,
	},
	{
		name: 'ParedeAzul',
		link: url.paredeAzul,
		icon: <GroupIcon />,
	},
	{
		name: 'Top Redes',
		link: url.topRedes,
		icon: <GroupIcon />,
	},
];

export const GestãoItemsLojas: IMenuItems[] = [
	{
		name: 'Gestão Lojas',
		url: url.gestLojas,
	},
	{
		name: 'Gestão Usuarios',
		url: url.gestUsuariosUrl,
		admin: true,
	},
	{
		name: 'Administração Lojas',
		url: url.adminLojasUrl,
		admin: true,
	},
	{
		name: 'Parametros',
		url: url.paramsUrl,
		admin: true,
	},
	{
		name: 'Upload Dados',
		url: url.dadosUrl,
		admin: true,
	},
];

export const GestãoDistItems: IMenuItems[] = [
	{
		url: url.procGamePlan,
		name: 'Game Plan',
	},
	{
		url: url.natModel,
		name: 'National Model',
	},
	{
		url: url.papelDist,
		name: 'Papel Distribuidor',
	},
	{
		url: url.planoInverno,
		name: 'Plano Inverno',
	},
	{
		url: url.rotaMercado,
		name: 'Rota ao Mercado',
	},
	{
		url: url.rotinaRCA,
		name: 'Rotina RCA',
	},
	{
		url: url.winDist,
		name: 'Win the Distributor',
	},
	{
		url: url.winShopper,
		name: 'Win the Shopper',
	},
	// {
	// 	url:url.definitions,
	// 	name: 'Definições'
	// },
];

export const ProcIndiItems: IMenuItems[] = [
	{
		url: url.creditoUrl,
		name: 'Crédito',
	},
	{
		url: url.procCadastro,
		name: 'Fluxo cadastro',
	},
	{
		url: url.logisticaUrl,
		name: 'Logística',
	},
	{
		url: url.msl,
		name: 'MSL',
	},
	{
		url: url.niveaLink,
		name: 'NiveaLink',
	},
];

export const ApnItems: IMenuItems[] = [
	{
		url: url.evaluation2021,
		name: 'Avaliação 2021',
	},
	{
		url: url.evaluation2022,
		name: 'Avaliação 2022',
	},
	{
		url: url.reqMinimos,
		name: 'Requisitos Mínimos',
	},
	{
		url: url.rankBrasil,
		name: 'Ranking Brasil',
	},
	{
		url: url.rankRegional,
		name: 'Ranking Regionais',
	},
	{
		url: url.matrizAPN,
		name: 'Matriz APN',
	},
	{
		url: url.analisisInd,
		name: 'Análise por Distribuidor',
	},
	{
		url: url.compComercial,
		name: 'Competência Comercial',
	},
	{
		url: url.compFinanc,
		name: 'Competência Financeira',
	},
	{
		url: url.compExec,
		name: 'Competência de Execução',
	},
	{
		url: url.CompTecnologia,
		name: 'Competência Tecnológica',
	},
	{
		url: url.CompRH,
		name: 'Competência RH',
	},
	{
		url: url.compLogistica,
		name: 'Competência Logística',
	},
	{
		url: url.planosReq,
		name: 'Plano de Ação - Requisitos 2022',
	},
	{
		url: url.planosOp,
		name: 'Plano de Ação - Oportunidades 2022',
	},
];

export const ProgramaItems: IMenuItems[] = [
	{
		url: url.paredeAzul,
		name: 'Parede Azul',
	},
	{
		url: url.topRedes,
		name: 'Top Redes',
	},
];

export const DesempenhoItems: IMenuItems[] = [
	{
		url: url.top15,
		name: 'Top 15',
	},
];
