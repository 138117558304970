import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import classNames from 'classnames';
import { FC, useState } from 'react';
import img2 from '../../../../images/apn/icon-execucao-pdv.png';
import img1 from '../../../../images/apn/icon-garantir-distribuicao.png';
import img3 from '../../../../images/apn/icon-nivel-servico.png';
import img4 from '../../../../images/apn/selo.png';
import { useStyles as useStylesDef } from '../../../GestDistribuidores/Definitions/styles';
import DynamicTable from '../components/DynamicTable';
import { useStyles } from '../styles';

const Eva2021: FC = () => {
	const classes = useStyles();
	const classesDef = useStylesDef();
	const [row, setrow] = useState([]);
	const [open, setopen] = useState(false);

	const handleOpenRow = () => {
		setopen(true);
	};
	const handleCloseRow = () => {
		setopen(false);
		setrow([]);
	};

	const handleConsulta = (rowIn: any) => {
		handleOpenRow();
		setrow(rowIn);
	};

	const columns: GridColDef[] = [
		{
			field: 'CNPJ',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 240,
		},
		{
			field: 'DISTRIBUIDOR',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			width: 558,
		},
		{
			field: 'DATA',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			width: 240,
		},
		{
			field: 'RESULTADO',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			width: 240,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<Button
						className={classes.button}
						variant='contained'
						sx={{ borderRadius: 8, maxHeight: 48 }}
						onClick={() => handleConsulta(params.row)}>
						<SearchIcon />
						Consultar
					</Button>
				);
			},
		},
	];

	const rows = [
		{
			id: 1,
			CNPJ: 3785066000122,
			DISTRIBUIDOR: 'DEC REAL',
			DATA: '9/7/2021, 8:17:10',
			RESULTADO: '',
		},
		{
			id: 2,
			CNPJ: 3785066000122,
			DISTRIBUIDOR: 'DEC REAL',
			DATA: '9/7/2021, 8:17:10',
			RESULTADO: '',
		},
	];

	return (
		<>
			<div className={classes.assessment}>
				<div className={classNames(classesDef.container, classes.grid2)}>
					<div className={classes.content}>
						<p style={{ marginTop: 80 }}>
							Para ser um <strong>DISTRIBUIDOR NIVEA</strong> é preciso ser aprovado pelo sistema de avaliação
							atingindo uma pontuação mínima exigida dentro dos <strong>critérios de avaliação</strong>.
						</p>

						<p>AVALIAÇÃO DA CAPACIDADE OPERACIONAL PARA:</p>

						<p>
							<img alt='icon' src={img1} style={{ maxWidth: 340 }} />
						</p>
						<p>
							<img alt='icon' src={img2} style={{ maxWidth: 340 }} />
						</p>
						<p>
							<img alt='icon' src={img3} style={{ maxWidth: 340 }} />
						</p>
					</div>
					<div className={classes.content}>
						<div className={classes.wrapperLeft}>
							<img alt='icon' src={img4} />
							<p className={classes.parragraph}>
								Acesso as condições comerciais e benefícios de relacionamento NIVEA
							</p>
						</div>
					</div>
				</div>
			</div>
			<DynamicTable columns={columns} row={row} rows={rows} handleCloseRow={handleCloseRow} open={open} />
		</>
	);
};

export default Eva2021;
