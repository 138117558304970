import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Frame from '../../../images/home/foto.png';

export const useStyles = makeStyles((theme: Theme) => ({
	panel: {
		margin: '20px 5% 0 5%',
		color: theme.palette.text.primary,
	},
	row: {
		display: 'flex',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	col: {
		float: 'left',
		boxSizing: 'border-box',
		padding: '0 0.75rem',
		minHeight: '1px',
	},
	text: {
		fontSize: 13,
		'& p': {
			margin: '20px 5% 0 5%',
		},
	},
	fotoGallery: {
		backgroundImage: `url(${Frame})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		maxWidth: '100%',
		height: 'auto',
		position: 'relative',
	},
	prev: {
		position: 'absolute',
		minHeight: '100%',
		minWidth: '1rem',
		top: 0,
		bottom: 0,
		left: '1.5rem',
	},
	next: {
		position: 'absolute',
		minHeight: '100%',
		minWidth: '1rem',
		top: 0,
		bottom: 0,
		right: '2rem',
	},
}));
