import Swal, { SweetAlertIcon } from 'sweetalert2';

export const swalLoading = () => {
	Swal.fire({
		icon: 'info',
		title: 'Verificando...',
		showCancelButton: false,
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			Swal.showLoading();
		},
	});
};

export const handleInfoText = (title: string, text: string) => {
	Swal.fire({
		icon: 'info',
		title: title,
		text: text || 'Error Access',
		customClass: { container: 'swal2-validated' },
		showConfirmButton: true,
		//timer: 2500,
	});
};

export const handleLoadingSave = () => {
	Swal.fire({
		icon: 'info',
		title: 'Guardando...',
		showConfirmButton: false,
		customClass: { container: 'swal2-validated' },
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			Swal.showLoading();
		},
	});
};

export const handleNotAccess = () => {
	Swal.fire({
		icon: 'info',
		title: 'No tienes acceso',
		text: 'Necesitas permisos',
		customClass: { container: 'swal2-validated' },
		showConfirmButton: true,
		//timer: 2500,
	});
};

export const handleError = (error: any) => {
	Swal.fire({
		icon: 'error',
		title: 'Error',
		text:
			error?.message ||
			error.response?.data?.originalError?.info.message ||
			error.response?.data?.message ||
			error?.response.data.originalError.info.message ||
			error.response.data.errors.map((err: any) => err.msg).join(', ') ||
			error?.response?.data.errors ||
			'Erro de acesso',
		customClass: { container: 'swal2-validated' },
		showConfirmButton: true,
	});
};

export const handleLoading = () => {
	Swal.fire({
		icon: 'info',
		title: 'Carregando...',
		text: 'Por favor espere',
		showConfirmButton: false,
		customClass: { container: 'swal2-validated' },
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			Swal.showLoading();
		},
	});
};

export const handleSuccess = (title: string, text: string) => {
	Swal.fire({
		icon: 'success',
		title: title,
		text: text || 'Error',
		customClass: { container: 'swal2-validated' },
		showConfirmButton: true,
	});
};

export const handleSwalAlert = (
	icon: SweetAlertIcon,
	title: string,
	text: string,
	confirmButton: boolean = false,
	allowOutsideClick: boolean = false,
	allowEscapeKey: boolean = false,
	didOpen: boolean = false,
) => {
	Swal.fire({
		icon,
		title: title,
		text: text,
		customClass: { container: 'swal2-validated' },
		showConfirmButton: confirmButton,
		allowOutsideClick,
		allowEscapeKey,
		didOpen: () => {
			if (didOpen) Swal.showLoading();
		},
	});
};
