import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import RTM1 from '../../../images/RTM/image.jpg';

const RotaaoMercado: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#2e2e2e' img={RTM1} title='ROTA AO MERCADO NIVEA' />
		</>
	);
};

export default RotaaoMercado;
