import { Theme, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gridClasses } from '@mui/x-data-grid';
import { ODD_OPACITY } from 'pages/APN/Evaluacion/styles';

export const elevation = 3;
export const rowHeight = 35;
export const styleModal = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '30%',
	bgcolor: 'background.paper',
	// border: '2px solid #000',
	boxShadow: 24,
};
export const styleModalLittle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '25%',
	bgcolor: 'background.paper',
	// border: '2px solid #000',
	boxShadow: 24,
};
export const styledCloseButton = {
	position: 'absolute',
	right: '1rem',
	top: '1rem',
	borderRadius: '50%',
	minWidth: 0,
	backgroundColor: (style: Theme) => `${style.palette.primary.main} !important`,
	borderColor: (style: Theme) => `${style.palette.primary.main} !important`,
	color: '#fff',
	width: 36,
	height: 36,
	'&:hover': {
		borderColor: (style: Theme) => `${style.palette.primary.light} !important`,
		backgroundColor: (style: Theme) => `${style.palette.primary.light} !important`,
	},
};
export const sxButtonError = {
	backgroundColor: (styles: Theme) => `${styles.palette.error.main} !important`,
	color: (styles: Theme) => `${styles.palette.error.contrastText} !important`,
	border: `none !important`,
	width: '100%',
	minWidth: 0,
	'&:hover': {
		backgroundColor: (styles: Theme) => `${styles.palette.error.light} !important`,
	},
};
export const sxButtonSuccess = {
	backgroundColor: (styles: Theme) => `${styles.palette.success.main} !important`,
	color: (styles: Theme) => `${styles.palette.success.contrastText} !important`,
	border: `none !important`,
	width: '100%',
	minWidth: 0,
	'&:hover': {
		backgroundColor: (styles: Theme) => `${styles.palette.success.light} !important`,
	},
};
export const sxButtonNormal = {
	backgroundColor: (styles: Theme) => `${styles.palette.primary.main} !important`,
	color: (styles: Theme) => `${styles.palette.primary.contrastText} !important`,
	border: `none !important`,
	width: '100%',
	minWidth: 0,
	'&:hover': {
		backgroundColor: (styles: Theme) => `${styles.palette.primary.light} !important`,
	},
};
export const sxButtonPurple = {
	backgroundColor: (styles: Theme) => `#5f6fcb !important`,
	color: (styles: Theme) => `${styles.palette.primary.contrastText} !important`,
	border: `none !important`,
	width: '100%',
	minWidth: 0,
	'&:hover': {
		backgroundColor: (styles: Theme) => `#5f6fcb !important`,
	},
};
export const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		minHeight: '100vh',
		margin: '0 5%',
		padding: '3rem 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
	},
	container1: {
		display: 'grid',
		minWidth: '100%',
		gridTemplateColumns: '7fr 5fr',
		gridColumnGap: '3rem',
	},
	papper1: {
		padding: '1rem',
		display: 'grid',
		gridTemplateColumns: '2fr 2fr 1fr',
		gridColumnGap: '1rem',
		justifyItems: 'center',
	},
	inputs: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
	},
	box: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: '8px',
		gridRowGap: '8px',
		// '& .MuiTypography-root': {
		// 	fontSize: '1rem',
		// },
	},
	innerBox: {
		border: '1px dashed #123475',
		padding: 4,
		display: 'flex',
		flexDirection: 'column',
		justifyItems: 'center',
		alignItems: 'center',
		borderRadius: '15px',
		// maxWidth: 120,
		// maxHeight: 120,

		// gridRowGap: 4,
	},
	amount: {
		fontWeight: 600,
		fontSize: '2rem',
		textAlign: 'center',
	},
	actions: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr 2fr',
		'& .MuiFormControlLabel-label': {
			color: theme.palette.primary.main,
		},
	},
	//RIGHT SIDE
	papper2: {
		padding: '1rem',
		display: 'grid',
		gridTemplateColumns: '0.5fr 0.3fr 1fr 0.3fr',
		// gridColumnGap: 8,
		justifyItems: 'center',
	},
	amountBox: {
		backgroundColor: '#ddeaf6',
		// padding: '2rem',
		textAlign: 'center',
		fontSize: '2rem',
		fontWeight: 600,
		color: '#000',
		border: '2px solid #31538f',
		borderRadius: '1rem',
	},
	statistics: {
		alignSelf: 'end',
		textAlign: 'center',
		fontSize: 20,
		color: '#000',
	},
	chart: {
		maxWidth: 208,
	},
	lojasWrapper: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridRowGap: '1rem',
		alignItems: 'center',
	},
	// Tabla
	container2: {
		display: 'flex',
		width: '100%',
		marginTop: '2rem',
	},
	headerBlue: {
		backgroundColor: '#d8e1f1',
	},
	headerGreen: {
		backgroundColor: '#e1efdb',
	},
	datagrid: {
		width: '100%',
		'& .header': {
			color: '#fff',
			fontWeight: 500,
		},
		'& .purple': {
			backgroundColor: '#a25dcb',
		},
		'& .blue': {
			backgroundColor: '#5d6ed2',
		},
		[`& .${gridClasses.row}.even`]: {
			backgroundColor: '#f1e9f6',
			'&:hover, &.Mui-hovered': {
				backgroundColor: '#edd9f9',
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
			'&.Mui-selected': {
				backgroundColor: alpha('#f1e9f6', ODD_OPACITY + theme.palette.action.selectedOpacity),
				'&:hover, &.Mui-hovered': {
					backgroundColor: alpha(
						'#f1e9f6',
						ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
					),
					// Reset on touch devices, it doesn't add specificity
					'@media (hover: none)': {
						backgroundColor: alpha('#f1e9f6', ODD_OPACITY + theme.palette.action.selectedOpacity),
					},
				},
			},
		},
	},
	valuesBad: {
		color: 'red',
		backgroundColor: '#d8e1f1',
	},
	rowDates: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '2rem',
		marginBottom: '2rem',
	},
	wrapperModal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		padding: 32,
	},
	titleModal: {
		fontWeight: 400,
		fontSize: 30,
		alignSelf: 'start',
		marginBottom: 8,
	},
	rowExclusionModal: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridRowGap: '1rem',
		alignItems: 'center',
		justifyItems: 'center',
		padding: '2rem',
		width: '100%',
	},
}));
