import { useStyles } from 'components/TitlePicturePage/styles';
import RTM1 from '../../../images/apn2022/08_competencia_execucao/Slide67.jpg';
import RTM2 from '../../../images/apn2022/08_competencia_execucao/Slide68.jpg';
import RTM3 from '../../../images/apn2022/08_competencia_execucao/Slide69.jpg';
import RTM4 from '../../../images/apn2022/08_competencia_execucao/Slide70.jpg';
import RTM5 from '../../../images/apn2022/08_competencia_execucao/Slide71.jpg';
import RTM6 from '../../../images/apn2022/08_competencia_execucao/Slide72.jpg';
import RTM7 from '../../../images/apn2022/08_competencia_execucao/Slide73.jpg';
import RTM8 from '../../../images/apn2022/08_competencia_execucao/Slide74.jpg';
import RTM9 from '../../../images/apn2022/08_competencia_execucao/Slide75.jpg';
import RTM10 from '../../../images/apn2022/08_competencia_execucao/Slide76.jpg';

import { FC } from 'react';
const CompExec: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>COMPETÊNCIA DE EXECUÇÃO</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img' />
				<img src={RTM2} alt='img' />
				<img src={RTM3} alt='img' />
				<img src={RTM4} alt='img' />
				<img src={RTM5} alt='img' />
				<img src={RTM6} alt='img' />
				<img src={RTM7} alt='img' />
				<img src={RTM8} alt='img' />
				<img src={RTM9} alt='img' />
				<img src={RTM10} alt='img' />
			</div>
		</>
	);
};

export default CompExec;
