import { useStyles } from 'components/TitlePicturePage/styles';
import RTM1 from '../../../images/apn2022/11_competencia_logistica/Slide83.jpg';
import RTM2 from '../../../images/apn2022/11_competencia_logistica/Slide84.jpg';
import RTM3 from '../../../images/apn2022/11_competencia_logistica/Slide85.jpg';
import RTM4 from '../../../images/apn2022/11_competencia_logistica/Slide86.jpg';
import RTM5 from '../../../images/apn2022/11_competencia_logistica/Slide87.jpg';
import RTM6 from '../../../images/apn2022/11_competencia_logistica/Slide88.jpg';
import RTM7 from '../../../images/apn2022/11_competencia_logistica/Slide89.jpg';
import RTM8 from '../../../images/apn2022/11_competencia_logistica/Slide90.jpg';
import RTM9 from '../../../images/apn2022/11_competencia_logistica/Slide91.jpg';
import RTM10 from '../../../images/apn2022/11_competencia_logistica/Slide92.jpg';
import RTM11 from '../../../images/apn2022/11_competencia_logistica/Slide93.jpg';
import RTM12 from '../../../images/apn2022/11_competencia_logistica/Slide94.jpg';
import RTM13 from '../../../images/apn2022/11_competencia_logistica/Slide95.jpg';
import RTM14 from '../../../images/apn2022/11_competencia_logistica/Slide96.jpg';
import RTM15 from '../../../images/apn2022/11_competencia_logistica/Slide97.jpg';
import RTM16 from '../../../images/apn2022/11_competencia_logistica/Slide98.jpg';
import RTM17 from '../../../images/apn2022/11_competencia_logistica/Slide99.jpg';

import { FC } from 'react';
const CompLogistica: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>COMPETÊNCIA LOGÍSTICA</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img' />
				<img src={RTM2} alt='img' />
				<img src={RTM3} alt='img' />
				<img src={RTM4} alt='img' />
				<img src={RTM5} alt='img' />
				<img src={RTM6} alt='img' />
				<img src={RTM7} alt='img' />
				<img src={RTM8} alt='img' />
				<img src={RTM9} alt='img' />
				<img src={RTM10} alt='img' />
				<img src={RTM11} alt='img' />
				<img src={RTM12} alt='img' />
				<img src={RTM13} alt='img' />
				<img src={RTM14} alt='img' />
				<img src={RTM15} alt='img' />
				<img src={RTM16} alt='img' />
				<img src={RTM17} alt='img' />
			</div>
		</>
	);
};

export default CompLogistica;
