import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const TopRedes = lazy(() => import('./TopRedes'));

const LazyTopRedes = () => {
	return (
		<Suspense fallback={<Loading />}>
			<TopRedes />
		</Suspense>
	);
};

export default LazyTopRedes;
