import Loading from 'components/LoadingBar';
import { MSLContextProvider } from 'context/MSL';
import { lazy, Suspense } from 'react';

const MSL = lazy(() => import('./MSL'));

const LazyMSL = () => {
	return (
		<Suspense fallback={<Loading />}>
			<MSLContextProvider>
				<MSL />
			</MSLContextProvider>
		</Suspense>
	);
};

export default LazyMSL;
