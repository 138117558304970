import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import classNames from 'classnames';
import { sxButtonPurple } from 'pages/GestLojas/styles';
import { ChangeEvent, FC, useState } from 'react';
import icon1 from '../../../../images/apn/button-downloadPlanilha.png';
import icon3 from '../../../../images/apn/button-politica.png';
import icon2 from '../../../../images/apn/button-uploadPlanilha.png';
import img2 from '../../../../images/apn/icon-execucao-pdv.png';
import img1 from '../../../../images/apn/icon-garantir-distribuicao.png';
import img3 from '../../../../images/apn/icon-nivel-servico.png';
import img4 from '../../../../images/apn/selo.png';
import doc1 from '../../../../images/apn2022/download/APN-Tool-Modelo-2022-v12.xlsx';
import politicaDist from '../../../../images/documents/politica-de-distribuidores.pdf';
import { useStyles as useStylesDef } from '../../../GestDistribuidores/Definitions/styles';
import DynamicTable from '../components/DynamicTable';
import { useStyles } from '../styles';

interface IRow {
	id: number;
	CNPJ: number;
	DISTRIBUIDOR: string;
	DATA: Date | string;
	RESULTADO: string;
	DOWNLOAD: {
		version: number;
		link: string;
	};
}

const Eva2022: FC = () => {
	const classes = useStyles();
	const classesDef = useStylesDef();

	const [row, setrow] = useState<IRow[]>([]);
	const [open, setopen] = useState(false);

	const handleOpenRow = () => {
		setopen(true);
	};
	const handleCloseRow = () => {
		setopen(false);
		setrow([]);
	};

	const handleConsulta = (rowIn: any) => {
		handleOpenRow();
		setrow(rowIn);
	};

	const columns: GridColDef[] = [
		{
			field: 'CNPJ',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			maxWidth: 250,
		},
		{
			field: 'DISTRIBUIDOR',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
		},
		{
			field: 'DATA',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			maxWidth: 210,
		},
		{
			field: 'RESULTADO',
			headerClassName: 'purple header',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			maxWidth: 170,
			renderCell: (params: GridRenderCellParams<IRow>) => {
				return (
					<Button
						className={classes.button}
						variant='contained'
						sx={{ borderRadius: 8, maxHeight: 48 }}
						onClick={() => handleConsulta(params.row)}>
						<SearchIcon />
						Consultar
					</Button>
				);
			},
		},
		{
			field: 'DOWNLOAD',
			headerClassName: 'blue header',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			maxWidth: 190,
			renderCell: (params: GridRenderCellParams<IRow>) => {
				const { DOWNLOAD } = params.row;
				return (
					<a style={{ color: '#039be5' }} href={DOWNLOAD.link} target='_blank' rel='noreferrer'>
						Download {`(v${DOWNLOAD.version})`}
					</a>
				);
			},
		},
	];

	const rows: IRow[] = [
		{
			id: 1,
			CNPJ: 71689228000189,
			DISTRIBUIDOR: 'COMERCIAL ALIMENTICIA PULMER L',
			DATA: '4/28/2022, 10:24:58 AM',
			RESULTADO: '',
			DOWNLOAD: {
				version: 4,
				link: 'https://nivea-pgn-client-backend.azurewebsites.net/file-server/ijtfbw_APN-Tool-Modelo-2022-v4%20PULMER.xlsx',
			},
		},
		{
			id: 2,
			CNPJ: 13793944000105,
			DISTRIBUIDOR: 'DISFER DISTRIBUIDORA DE PRODUT',
			DATA: '4/28/2022, 10:19:10 AM',
			RESULTADO: '',
			DOWNLOAD: {
				version: 4,
				link: 'https://nivea-pgn-client-backend.azurewebsites.net/file-server/cdme57_APN-Tool-Modelo-2022-Disfer.xlsx',
			},
		},
		{
			id: 3,
			CNPJ: 3785066000122,
			DISTRIBUIDOR: 'DISFER DISTRIBUIDORA DE PRODUT',
			DATA: '4/8/2022, 10:26:22 AM',
			RESULTADO: '',
			DOWNLOAD: {
				version: 1,
				link: 'https://nivea-pgn-client-backend.azurewebsites.net/file-server/hgjo0c_APN-Tool-Modelo-2022_v1-preenchido.xlsm',
			},
		},
	];

	// UploadButton header Data
	const [upload, setupload] = useState(false);
	const [fileName, setfileName] = useState(``);
	const [file, setFile] = useState<File | null>(null);
	const handleOpenUpload = () => setupload(true);
	const handleCloseUpload = () => setupload(false);

	const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event?.target?.files && event.target.files[0]) {
			let file_aux: File = event.target.files[0];
			setFile(file_aux);
			setfileName(file_aux.name);
			console.log(file_aux.name);
			console.log(file);
		}
		event.target.value = '';
	};

	return (
		<>
			<div className={classes.assessment}>
				<div className={classNames(classesDef.container, classes.grid2)}>
					<div className={classes.content}>
						<p style={{ marginTop: 80 }} className={classes.apnTitle}>
							APN 2022
						</p>
						<p>
							Para ser um <strong>DISTRIBUIDOR NIVEA</strong> é preciso ser aprovado pelo sistema de avaliação
							atingindo uma pontuação mínima exigida dentro dos <strong>critérios de avaliação</strong>.
						</p>

						<p>AVALIAÇÃO DA CAPACIDADE OPERACIONAL PARA:</p>

						<p>
							<img alt='icon' src={img1} style={{ maxWidth: 340 }} />
						</p>
						<p>
							<img alt='icon' src={img2} style={{ maxWidth: 340 }} />
						</p>
						<p>
							<img alt='icon' src={img3} style={{ maxWidth: 340 }} />
						</p>
					</div>
					<div className={classes.content}>
						<div className={classes.wrapperLeft}>
							<img alt='icon' src={img4} />
							<p className={classes.parragraph}>
								Acesso as condições comerciais e benefícios de relacionamento NIVEA
							</p>
							<p style={{ fontSize: '1rem' }}>
								<u>Baixe os arquivos </u> abaixo e preencha com as informações necessárias para a aprovação no
								sistema de avaliação.
							</p>
							<div className={classes.buttonsWrapper}>
								<div className={classes.column}>
									<a href={doc1} rel='noreferrer' target='_blank'>
										<img alt='icon' src={icon1} />
									</a>
								</div>
								<div className={classes.column}>
									<div onClick={handleOpenUpload}>
										<img alt='icon' className='btn-upload' src={icon2} />
									</div>
								</div>
								<div className={classes.column}>
									<a href={politicaDist} rel='noreferrer' target='_blank'>
										<img alt='icon' src={icon3} />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{upload ? (
				<div className={classes.containerUpload}>
					<div className={classes.wrapperInput}>
						<div
							style={{
								backgroundColor: '#fff',
								borderRadius: '50px',
								padding: '10px 20px',
								color: '#5f70cb',
								marginLeft: '13px',
								maxWidth: 203,
							}}>
							Localizar arquivo XLSX
						</div>
						<div
							style={{
								backgroundColor: '#fff',
								borderRadius: '50px',
								padding: '10px 20px',
								color: '#5f70cb',
								marginLeft: '13px',
								minHeight: 44,
							}}>
							{fileName}
						</div>

						<Button
							size='small'
							variant={'contained'}
							component='label'
							sx={{ display: 'flex', alignItems: 'end', minWidth: 0, maxWidth: 203, justifySelf: 'center' }}>
							Selecione o arquivo
							<UploadIcon />
							<input type='file' accept='.xlsx, .xls, .csv' hidden onChange={handleFile} />
						</Button>
					</div>
					<div className={classes.wrapperButton}>
						<Button
							style={{ gridColumn: 6, background: '#5f6fcb !important' }}
							sx={sxButtonPurple}
							variant='outlined'
							onClick={handleCloseUpload}>
							Cancelar/Voltar
						</Button>
					</div>
				</div>
			) : (
				<DynamicTable columns={columns} row={row} rows={rows} handleCloseRow={handleCloseRow} open={open} />
			)}
		</>
	);
};

export default Eva2022;
