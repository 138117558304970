import classNames from 'classnames';
import { FC } from 'react';
import icon3 from '../../../images/definicoes/icon-area-geografica.png';
import icon6 from '../../../images/definicoes/icon-capacidade-financeira.png';
import icon7 from '../../../images/definicoes/icon-capacidade-logistica.png';
import icon5 from '../../../images/definicoes/icon-colaboracao-comercial.png';
import icon1 from '../../../images/definicoes/icon-distribuidor.png';
import icon4 from '../../../images/definicoes/icon-equipe-distribuidor.png';
import icon2 from '../../../images/definicoes/icon-processos-comerciais.png';
import { useStyles } from './styles';

const Definitions: FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<div className={classes.titleBar}>
				<p className={classes.title}>DEFINIÇÕES</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classNames(classes.grid3, classes.container)}>
				<div className=''>
					<div className={classes.itemBox}>
						<img src={icon1} alt='icon' />
						<div className={classes.subtitle}>Distribuidor</div>
						<div className={classes.content}>
							<p>
								Caracteriza-se por DISTRIBUIDOR clientes com foco na qualidade de distribuição e execução de lojas
								do segmento de 5a9 check-outs, podendo atender demais segmentos com a anuência da NIVEA.
							</p>
							<p>
								Um DISTRIBUIDOR NIVEA atenderá uma determinada área geográfica de forma semi-­exclusiva, podendo
								haver mais de um agente de distribuição, quer seja Atacadista, Distribuidor Especializado Cosmético
								ou demais agentes intermediários.
							</p>
							<p>
								0 DISTRIBUIDOR não poderá conter concorrentes diretos aos produtos NIVEA em sua pasta de vendas.
							</p>
						</div>
					</div>
					<div className={classes.itemBox}>
						<img src={icon2} alt='icon' />
						<div className={classes.subtitle}>Processos/Gestão comercial</div>
						<div className={classes.content}>
							<p>
								A relação entre a NIVEA e o DISTRIBUIDOR deve ser pautada em reuniões comerciais cíclicas para
								endereçar as questões comercias administrativas, e também por fóruns de revisão comercial
								trimestral para discussão e alinhamento das ações do joint business plan (JBP).
							</p>
						</div>
					</div>
					<div className={classes.itemBox}>
						<img src={icon2} alt='icon' />
						<div className={classes.subtitle}>Critérios de Avaliação</div>
						<div className={classes.content}>
							<p>O DISTRIBUIDOR será ciclicamente submetido a constantes avaliações de performance que visam:</p>
							<ul>
								<li>Corrigir sua performance de vendas e/ou execução; e</li>
								<li>Assegurar sua manutenção como distribuidor;</li>
							</ul>
						</div>
					</div>
				</div>
				<div className=''>
					<div className={classes.itemBox}>
						<img src={icon3} alt='icon' />
						<div className={classes.subtitle}>Área geográfica</div>
						<div className={classes.content}>
							<p>
								A área geográfica é a definição do grupo de municípios, designada de forma única/unilateral pela
								NIVEA, que o distribuidor terá o direito de comercializar os produtos NIVEA de forma não-exclusiva.
								Esta definição fará parte do contrato de fornecimento presente no Anexo IV da política de
								distribuidores.
							</p>
						</div>
					</div>
					<div className={classes.itemBox}>
						<img src={icon4} alt='icon' />
						<div className={classes.subtitle}>Equipe do distribuidor</div>
						<div className={classes.content}>
							<p>
								Entende-se por equipe do DISTRIBUIDOR todo o time envolvido na comercialização e execução dos
								produtos NIVEA no mercado, ficando o DISTRIBUIDOR como único e total responsável pela atuação dos
								mesmos.
							</p>
							<p>
								Para ser caracterizado como um distribuidor NIVEA, o cliente deve respeitar a legislação
								trabalhista vigente no local de operação, tanto nas contratações quanto nas relações com os atuais
								funcionários.
							</p>
						</div>
					</div>
					<div className={classes.itemBox}>
						<img src={icon5} alt='icon' />
						<div className={classes.subtitle}>Colaboração comercial</div>
						<div className={classes.content}>
							<p>
								Fica definido como Colaboração Comercial a relação entre a NIVEA e o DISTRIBUIDOR visando definir,
								revisar e/ou ajustar os objetivos das alavancas de performance previamente estabelecidas no JBP,
								salvo definição unilaterais da NIVEA expressas neste documento.
							</p>
							<p>
								0 time de vendas NIVEA possui obrigatoriedade de realizar reuniões mensais com o DISTRIBUIDOR para
								compartilhar as prioridades daquele ciclo. Os custos dessas reuniões, com o time completo do
								DISTRIBUIDOR, são de responsabilidade do mesmo.
							</p>
						</div>
					</div>
				</div>
				<div className=''>
					<div className={classes.itemBox}>
						<img src={icon6} alt='icon' />
						<div className={classes.subtitle}>Capacidade financeira</div>
						<div className={classes.content}>
							<p>
								O DISTRIBUIDOR NIVEA deve ter sua capacidade financeira avaliada utilizando os critérios
								explicitados no Avaliação de Performance Nivea​ e também validado pelo Departamento de Tesouraria
								da NIVEA. Para isso, a NIVEA requisitará toda documentação necessária para suportar sua análise.
							</p>
							<p>
								Para sustentar um nível saudável de operação, recomenda-se por padrão NIVEA que o DISTRIBUIDOR
								opere com no mínimo 60 dias de estoque, estando sua capacidade financeira compatível com esse
								requisito.
							</p>
							<p>
								Nesse item, serão avaliadas questões como: prazo de pagamento oferecido aos clientes do
								distribuidor, áreas foco de investimento (frota, equipe, treinamento, tecnologia, etc), margem de
								operação nas principais categorias, acompanhamento de indicadores financeiros além de uma análise
								de crédito feita pelo time da NIVEA.
							</p>
						</div>
					</div>
					<div className={classes.itemBox}>
						<img src={icon7} alt='icon' />
						<div className={classes.subtitle}>Capacidade logística</div>
						<div className={classes.content}>
							<p>
								0 DISTRIBUIDOR deve possuir um ou mais depósitos com todas as licenças exigidas pela regulamentação
								local para armazenar, manusear e despachar os produtos NIVEA - e de integral responsabilidade do
								DISTRIBUIDOR fazer o picking de 100% do portfolio NIVEA.
							</p>
							<p>
								Deverá o DISTRIBUIDOR compartilhar com a NIVEA seu nível de serviço ao mercado para garantir que
								qualidade do atendimento as lojas da área designada.
							</p>
							<p>
								O fluxo de pedidos do DISTRIBUIDOR a NIVEA deverá respeitar o calendário de geração de carteira da
								NIVEA. Desta forma as partes deverao de forma antecipada planejar o recebimento das mercadorias
								visando aumentar a produtividade para ambas as partes.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className=''>
				<div className={classes.separator} style={{ marginTop: '4rem', marginBottom: '1rem' }}></div>
				<div className={classes.content} style={{ textAlign: 'center', marginBottom: '2rem', fontSize: '1rem' }}>
					<p>Além do Avaliação de Performance Nivea​, o distribuidor será avaliado pelos seguintes critérios: </p>
				</div>
			</div>
			<div className={classes.container}>
				<div className={classes.col}>
					<table className={classes.table}>
						<thead>
							<tr>
								<th rowSpan={2}>KPI</th>
								<th rowSpan={2}>Descrição</th>
								<th colSpan={3}>Avaliação</th>
							</tr>
							<tr>
								<th className={classes.tableSubtitle}>Atingido</th>
								<th className={classes.tableSubtitle}>Parcial</th>
								<th className={classes.tableSubtitle}>Não atingido</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={classes.tableTitle}>Mix Skin Care</td>
								<td className={classes.tableSubtitle}>
									Ao menos 25% do Sell-out !RSI do distribuidor devem ser de Skin Care
								</td>
								<td className={classes.tableValue}>&gt;25%</td>
								<td className={classes.tableValue}>&gt;=20% e &lt;25%</td>
								<td className={classes.tableValue}>&lt;20%</td>
							</tr>
							<tr>
								<td className={classes.tableTitle}>% de venda na área geográfica designada </td>
								<td className={classes.tableSubtitle}>
									O Distribuidor deve ter a venda focada no área geográfica destinada a ele em conjunto com a NIVEA
								</td>
								<td className={classes.tableValue}>&gt;85%</td>
								<td className={classes.tableValue}>&gt;=75% e &lt;85%</td>
								<td className={classes.tableValue}>&lt;75%</td>
							</tr>
							<tr>
								<td className={classes.tableTitle}>Sell-Out</td>
								<td className={classes.tableSubtitle}>Atingimento do meta mensal de sell-out em valor (R$)</td>
								<td className={classes.tableValue}>&gt;100%</td>
								<td className={classes.tableValue}>&gt;=95% e &lt;100%</td>
								<td className={classes.tableValue}>&lt;95%</td>
							</tr>
							<tr>
								<td className={classes.tableTitle}>Sell-In</td>
								<td className={classes.tableSubtitle}>Atingimento do meta mensal de sell-in em valor (R$)</td>
								<td className={classes.tableValue}>&gt;100%</td>
								<td className={classes.tableValue}>&gt;=95% e &lt;100%</td>
								<td className={classes.tableValue}>&lt;95%</td>
							</tr>
							<tr>
								<td className={classes.tableTitle}>Positivação</td>
								<td className={classes.tableSubtitle}>
									Atingir % de lojas positivadas vs o universo atendido pelo distribuidor
								</td>
								<td className={classes.tableValue}>&gt;75%</td>
								<td className={classes.tableValue}>&gt;=60% e &lt;75%</td>
								<td className={classes.tableValue}>&lt;60%</td>
							</tr>
							<tr>
								<td className={classes.tableTitle}>Sortimento</td>
								<td className={classes.tableSubtitle}>
									% da venda Sell-Out do distribuidor deve ser do sortimento recomendado
								</td>
								<td className={classes.tableValue}>&gt;50%</td>
								<td className={classes.tableValue}>&gt;40% e &lt;50%</td>
								<td className={classes.tableValue}>&lt;=40%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Definitions;
