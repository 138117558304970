import HomeVideoMP4 from 'images/downloads/videos/tutorial.mp4';
import Banner from 'images/home/banner.jpg';
import Frame from 'images/home/foto.png';
import uno from 'images/home/gallery/Fotos-Nivea-1.jpg';
import diez from 'images/home/gallery/Fotos-Nivea-10.jpg';
import once from 'images/home/gallery/Fotos-Nivea-11.jpg';
import doce from 'images/home/gallery/Fotos-Nivea-12.jpg';
import dos from 'images/home/gallery/Fotos-Nivea-2.jpg';
import tres from 'images/home/gallery/Fotos-Nivea-3.jpg';
import cuatro from 'images/home/gallery/Fotos-Nivea-4.jpg';
import cinco from 'images/home/gallery/Fotos-Nivea-5.jpg';
import seis from 'images/home/gallery/Fotos-Nivea-6.jpg';
import siete from 'images/home/gallery/Fotos-Nivea-7.jpg';
import ocho from 'images/home/gallery/Fotos-Nivea-8.jpg';
import nueve from 'images/home/gallery/Fotos-Nivea-9.jpg';
import HomeVideo from 'images/home/text-Video.jpg';
import HealthCheck from 'images/home/title-HealthCheck.jpg';
import ParedAzul from 'images/home/title-ParedeAzul.jpg';
import IndicadoresHome from 'images/indicadores/home.png';

import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const Home: FC = () => {
	const classes = useStyles();
	const [index, setIndex] = useState(0);
	const data = [uno, dos, tres, cuatro, cinco, seis, siete, ocho, nueve, diez, once, doce];

	const containerStyle = {
		backgroundImage: `url(${data[index]})`,
	};

	const next = () => {
		setIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
	};

	const prior = () => {
		setIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
	};

	return (
		<div>
			<img src={Banner} alt='banner' />
			<div className={classes.panel}>
				<div className={classes.row}>
					<div className={classes.col} style={{ width: '25%' }}>
						<img src={HealthCheck} alt='HealthCheck' />
						<div className={classes.text}>
							<p>
								O Health Check é uma visão consolidada dos principais indicadores do canal indireto. Aqui temos a
								visão do mês vigente, 3 últimos meses fechados e Year To Date (YTD) 2021, além das suas comparações
								versus ano passado (2020).
							</p>
							<p>
								Com uma gestão compilada, você tem acesso ao estoque, DOH, positivação, Drop Size, Penetração dos
								itens e a Positivação dos TOP 10 itens Nivea em um só lugar.
							</p>
						</div>
					</div>
					<div className={classes.col} style={{ width: '75%' }}>
						<img src={IndicadoresHome} alt='indicadores' />
					</div>
				</div>
				<div className={classes.row} style={{ marginTop: '1.5rem' }}>
					<div className={classes.col} style={{ width: '25%' }}>
						<img src={ParedAzul} alt='ParedAzul' />
						<div className={classes.text}>
							<p>Confira fotos de sucesso das positivações feitas do Parede Azul.</p>
						</div>
					</div>
					<div className={classes.col} style={{ width: '75%' }}>
						<div className={classes.col} style={{ position: 'relative' }}>
							<img src={Frame} alt='cuadro' className={classes.fotoGallery} style={containerStyle} />
							<button
								className={classes.prev}
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									prior();
								}}
								title='Anterior'
							/>
							<button
								className={classes.next}
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									next();
								}}
								title='Próximo'
							/>
						</div>
						<div className={classes.col}>
							<Link to={HomeVideoMP4}>
								<img src={HomeVideo} alt='HomeVideo' />
							</Link>
						</div>
					</div>
				</div>
			</div>
			.
		</div>
	);
};

export default Home;
