import Loading from 'components/LoadingBar';
import { FC, Suspense, lazy } from 'react';
import { IDescComercio } from './DescComercio';

const DescComercio = lazy(() => import('./DescComercio'));

const LazyDescComercio: FC<IDescComercio> = ({ row }) => {
	return (
		<Suspense fallback={<Loading />}>
			<DescComercio row={row} />
		</Suspense>
	);
};

export default LazyDescComercio;
