import TitlePicturePage from 'components/TitlePicturePage';
import RTM1 from 'images/fluxoCadastro/image.jpg';
import { FC } from 'react';

const FluxoCadastro: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img={RTM1} title='PROCESSO DE CADASTRO DE CLIENTES' />
		</>
	);
};

export default FluxoCadastro;
