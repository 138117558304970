import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const Landing = lazy(() => import('./Landing'));

const LazyLanding = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Landing />
		</Suspense>
	);
};

export default LazyLanding;
