import axios from 'axios';
import { configure } from 'axios-hooks';

const dev = `https://back-end-nivea-hml-bca4.up.railway.app/api`;

const useAxios = axios.create({
	baseURL: process.env.REACT_APP_APIURL ? process.env.REACT_APP_APIURL : dev,
});

useAxios.defaults.headers['Content-Type'] = 'application/json';

useAxios.interceptors.request.use((config) => {
	if (config.headers) {
		const token = localStorage.getItem('token');
		config.headers['Authorization'] = `Bearer ${token}`;
		config.headers['token'] = `${token}`;
	}
	return config;
});

useAxios.interceptors.response.use(
	(resp) => {
		if (resp.data.token) {
			localStorage.setItem('token', resp.data.token);
			resp.headers.Authorization = resp.data.token;
		}
		return resp;
	},
	async (error) => {
		if (error.response.status === 401) {
			//hacer alguna logica de unauthorized
			// localStorage.removeItem('token');
		}
		return error;
	},
);

//const cache = new LRU({ max: 10 });

configure({ axios });
//
export default useAxios;
