import { ptBR as corePtBr } from '@mui/material/locale';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ParametrosContextProvider } from 'context/Parametros';
import { pt } from 'date-fns/locale';
import { useMemo } from 'react';
import './App.css';
import Router from './router';

function App() {
	const theme = useMemo(
		() =>
			createTheme(
				{
					palette: {
						// mode: mode,
						primary: {
							main: '#073372',
						},
						// secondary: {},
						// error: {},
						// warning: {},
						// info:{},
						// success:{},
						text: {
							primary: '#1b3471',
						},
					},
				},
				pt,
				ptBR,
				corePtBr,
			),
		[],
	);

	return (
		<StyledEngineProvider injectFirst>
			<ParametrosContextProvider>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
					<ThemeProvider theme={theme}>
						<Router />
					</ThemeProvider>
				</LocalizationProvider>
			</ParametrosContextProvider>
		</StyledEngineProvider>
	);
}

export default App;
