import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import RTM1 from '../../../images/processoGamePlan/image.jpg';

const ProcGamePlan: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img={RTM1} title='PROCESSO GAME PLAN' />
		</>
	);
};

export default ProcGamePlan;
