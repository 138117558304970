import { handleError, handleLoading } from 'components/Alert';
import useAxios from 'config';
import { getChangeAddLojas, getParamsApp } from 'config/endpoints';
import { ReactNode, createContext, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { IParameterContext } from './interfaces';

interface Props {
	children: ReactNode;
}

const ParametrosContext = createContext<IParameterContext>({
	AddLojas: false,
	handleSwitchAddLojas: () => new Promise(() => false),
});

export const ParametrosContextProvider = ({ children }: Props) => {
	const [AddLojas, setAddLojas] = useState(false);

	const getData = async () => {
		try {
			const res = await useAxios.get(getParamsApp).then((resp) => resp.data);
			const { ADD_LOJAS } = res;
			setAddLojas(ADD_LOJAS);
		} catch (error) {
			handleError(error);
		}
	};

	const handleSwitchAddLojas: () => Promise<boolean> = async () => {
		try {
			handleLoading();
			const res = await useAxios.get(getChangeAddLojas).then((res) => res.data);
			const { ADD_LOJAS } = res;
			setAddLojas(ADD_LOJAS);
			Swal.close();
			return ADD_LOJAS;
		} catch (error) {
			return false;
		}
	};

	useMemo(() => {
		getData();
	}, []);
	return (
		<ParametrosContext.Provider
			value={{
				AddLojas,
				handleSwitchAddLojas,
			}}>
			{children}
		</ParametrosContext.Provider>
	);
};

export default ParametrosContext;
