import { useStyles } from 'components/TitlePicturePage/styles';
import { FC } from 'react';
import RTM1 from '../../../images/apn2022/01_requisitos_minimos/Slide1.jpg';
import RTM2 from '../../../images/apn2022/01_requisitos_minimos/Slide2.jpg';
import RTM3 from '../../../images/apn2022/01_requisitos_minimos/Slide3.jpg';
import RTM4 from '../../../images/apn2022/01_requisitos_minimos/Slide4.jpg';
const ReqMinimos: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>REQUISITOS MÍNIMOS</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img1' />
				<img src={RTM2} alt='img2' />
				<img src={RTM3} alt='img3' />
				<img src={RTM4} alt='img4' />
			</div>
		</>
	);
};

export default ReqMinimos;
