import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IData {
	cargo: string;
	id: number;
	name: string;
	perfil: string;
	phone: string;
	username: string;
}
export interface AuthState {
	token: string;
	user: boolean;
	data: IData | undefined;
}

const initialState: AuthState = {
	token: ``,
	user: false,
	data: undefined,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<AuthState>) => {
			Object.assign(state, action.payload);
		},
		clearAuth: (state) => {
			Object.assign(state, initialState);
		},
	},
});

export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;
