import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import img from '../../../../images/definicoes/background.jpg';

export const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		backgroundColor: '#fff',
		paddingBottom: '4rem',
		backgroundImage: `url(${img})`,
		backgroundPosition: 'top center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundAttachment: 'fixed',
	},
	row: {
		display: 'flex',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	titleBar: {
		padding: 1,
	},
	title: {
		color: '#333',
		margin: '0.5rem',
		textAlign: 'center',
		fontSize: '1.67rem',
	},
	separator: {
		backgroundColor: '#2b53d1',
		margin: '1rem auto 1.5rem auto',
		width: 60,
		height: 4,
	},
	container: {
		margin: '0 auto',
		maxWidth: 1280,
		width: '90%',
		[theme.breakpoints.up('sm')]: {
			width: '85%',
		},
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
	},
	grid3: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
	},
	itemBox: {
		padding: '2rem',
		border: '1px solid #f9f9f9',
		WebkitBoxShadow: 'inset -1px -12px 55px -56px rgba(0,0,0,0.75)',
		MozBoxShadow: 'inset -1px -12px 55px -56px rgba(0,0,0,0.75)',
		boxShadow: 'inset -1px -12px 55px -56px rgba(0,0,0,0.75)',
	},
	subtitle: {
		color: '#333',
		margin: '0.5rem 0 0 0',
		textAlign: 'left',
		fontSize: '1.34rem',
		fontWeight: 400,
	},
	content: {
		color: '#333',
		textAlign: 'left',
		margin: '0.5rem 0 0 0',
		fontSize: '0.83rem',
		'& p': {
			margin: '1em 0',
		},
	},
	col: {
		padding: '0 0.75rem',
	},
	table: {
		'& thead th': {
			backgroundColor: '#2b53d1',
			color: '#fff',
		},
		'& tr:nth-child(even)': {
			backgroundColor: '#f2f2f2',
		},
	},
	tableSubtitle: {
		fontSize: '0.779rem',
		borderLeft: '1px solid #fff',
		width: '10%',
		padding: '0.3rem',
	},
	tableValue: {
		fontSize: '0.89rem',
		fontWeight: 'bold',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		padding: '1rem',
	},
	tableTitle: {
		fontSize: '0.89rem',
		padding: '1rem',
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textAlign: 'left',
	},
}));
