import AnalisisIndiv from 'pages/APN/AnalisisIndiv';
import CompComercial from 'pages/APN/CompComercial';
import CompExec from 'pages/APN/CompExecucao';
import CompFinanc from 'pages/APN/CompFinanc';
import CompLogistica from 'pages/APN/CompLogistica';
import CompRH from 'pages/APN/CompRH';
import CompTecnologia from 'pages/APN/CompTecnologia';
import Eva2021 from 'pages/APN/Evaluacion/2021';
import Eva2022 from 'pages/APN/Evaluacion/2022';
import MAtrizAPN from 'pages/APN/MatrizAPN';
import Oportunidades2022 from 'pages/APN/PlanoAcao/Oportunidades2022';
import Requisitos2022 from 'pages/APN/PlanoAcao/Requisitos2022';
import RankBrasil from 'pages/APN/RankBrasil';
import RankRegional from 'pages/APN/RankRegional';
import ReqMinimos from 'pages/APN/ReqMinimos';
import LazyAdminLojas from 'pages/AdminLojas';
import LazyTop15 from 'pages/Desempenho/Top15';
import Definitions from 'pages/GestDistribuidores/Definitions';
import NationalModel from 'pages/GestDistribuidores/NationalModel';
import PapelDistribuidor from 'pages/GestDistribuidores/PapelDistribuidor';
import PlanoInverno from 'pages/GestDistribuidores/PlanoInverno';
import ProcGamePlan from 'pages/GestDistribuidores/ProcessoGamePlan';
import RotaaoMercado from 'pages/GestDistribuidores/Rota ao Mercado';
import RotinaRCA from 'pages/GestDistribuidores/RotinaRCA';
import WinDist from 'pages/GestDistribuidores/WinDist';
import WinShopper from 'pages/GestDistribuidores/WinShopper';
import LazyGestLojas from 'pages/GestLojas';
import LazyGestaoUser from 'pages/GestaoUsers';
import Home from 'pages/Home';
import LoadFile from 'pages/LoadFile';
import LazyParams from 'pages/Params';
import Credito from 'pages/ProcIndiretos/Credito';
import FluxoCadastro from 'pages/ProcIndiretos/FluxoCadastro';
import Logistica from 'pages/ProcIndiretos/Logistica';
import LazyMSL from 'pages/ProcIndiretos/MSL';
import NiveaLink from 'pages/ProcIndiretos/NiveaLink';
import LazyParedeAzul from 'pages/Programas/ParedeAzul';
import LazyTopRedes from 'pages/Programas/TopRedes';
import LazyUploadDados from 'pages/UploadDados';
import * as url from 'router/routes/Urls';
import { IRoute } from '../../interfaces';

const Private: IRoute[] = [
	{
		path: url.home,
		element: <Home />,
	},
	{
		path: url.papelDist,
		element: <PapelDistribuidor />,
	},
	{
		path: url.rotaMercado,
		element: <RotaaoMercado />,
	},
	{
		path: url.natModel,
		element: <NationalModel />,
	},
	{
		path: url.procGamePlan,
		element: <ProcGamePlan />,
	},
	{
		path: url.planoInverno,
		element: <PlanoInverno />,
	},
	{
		path: url.rotinaRCA,
		element: <RotinaRCA />,
	},
	{
		path: url.winDist,
		element: <WinDist />,
	},
	{
		path: url.winShopper,
		element: <WinShopper />,
	},
	{
		path: url.definitions,
		element: <Definitions />,
	},
	{
		path: url.procCadastro,
		element: <FluxoCadastro />,
	},
	{
		path: url.reqMinimos,
		element: <ReqMinimos />,
	},
	{
		path: url.rankRegional,
		element: <RankRegional />,
	},
	{
		path: url.rankBrasil,
		element: <RankBrasil />,
	},
	{
		path: url.matrizAPN,
		element: <MAtrizAPN />,
	},
	{
		path: url.analisisInd,
		element: <AnalisisIndiv />,
	},
	{
		path: url.compComercial,
		element: <CompComercial />,
	},
	{
		path: url.compFinanc,
		element: <CompFinanc />,
	},
	{
		path: url.compExec,
		element: <CompExec />,
	},
	{
		path: url.CompTecnologia,
		element: <CompTecnologia />,
	},
	{
		path: url.CompRH,
		element: <CompRH />,
	},
	{
		path: url.compLogistica,
		element: <CompLogistica />,
	},
	{
		path: url.niveaLink,
		element: <NiveaLink />,
	},
	{
		path: url.loadFile,
		element: <LoadFile />,
	},
	{
		path: url.msl,
		element: <LazyMSL />,
	},
	{
		path: url.creditoUrl,
		element: <Credito />,
	},
	{
		path: url.logisticaUrl,
		element: <Logistica />,
	},
	{
		path: url.evaluation2021,
		element: <Eva2021 />,
	},
	{
		path: url.evaluation2022,
		element: <Eva2022 />,
	},
	{
		path: url.gestLojas,
		element: <LazyGestLojas />,
	},
	{
		path: url.adminLojasUrl,
		element: <LazyAdminLojas />,
	},
	{
		path: url.gestUsuariosUrl,
		element: <LazyGestaoUser />,
		admin: true,
	},
	{
		path: url.paramsUrl,
		element: <LazyParams />,
	},
	{
		path: url.dadosUrl,
		element: <LazyUploadDados />,
	},
	{
		path: url.planosReq,
		element: <Requisitos2022 />,
	},
	{
		path: url.planosOp,
		element: <Oportunidades2022 />,
	},
	{
		path: url.paredeAzul,
		element: <LazyParedeAzul />,
	},
	{
		path: url.topRedes,
		element: <LazyTopRedes />,
	},
	{
		path: url.top15,
		element: <LazyTop15 />,
	},
];

export default Private;
