import { useStyles } from 'components/TitlePicturePage/styles';
import { FC } from 'react';
import RTM1 from '../../../images/apn2022/06_competencia_comercial/Slide50.jpg';
import RTM2 from '../../../images/apn2022/06_competencia_comercial/Slide51.jpg';
import RTM3 from '../../../images/apn2022/06_competencia_comercial/Slide52.jpg';
import RTM4 from '../../../images/apn2022/06_competencia_comercial/Slide53.jpg';
import RTM5 from '../../../images/apn2022/06_competencia_comercial/Slide54.jpg';
import RTM6 from '../../../images/apn2022/06_competencia_comercial/Slide55.jpg';
import RTM7 from '../../../images/apn2022/06_competencia_comercial/Slide56.jpg';
import RTM8 from '../../../images/apn2022/06_competencia_comercial/Slide57.jpg';
const CompComercial: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>COMPETÊNCIA COMERCIAL</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img' />
				<img src={RTM2} alt='img' />
				<img src={RTM3} alt='img' />
				<img src={RTM4} alt='img' />
				<img src={RTM5} alt='img' />
				<img src={RTM6} alt='img' />
				<img src={RTM7} alt='img' />
				<img src={RTM8} alt='img' />
			</div>
		</>
	);
};

export default CompComercial;
