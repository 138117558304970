import { Theme, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gridClasses } from '@mui/x-data-grid';

export const ODD_OPACITY = 0.2;

export const useStyles = makeStyles((theme: Theme) => ({
	//Banner
	assessment: {
		background: 'linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 49%, rgba(242,242,242,1) 100%)',
		paddingBottom: '3rem',
	},
	grid2: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	},
	content: {
		color: '#282828',
		padding: '0 0.75rem',
		textAlign: 'left',
		margin: '0.5rem 0 0 4rem',
		fontSize: '1.12rem',
		'& p': {
			margin: '1.5rem 0',
		},
	},
	parragraph: {
		color: '#5eb9f1',
		textAlign: 'center',
		margin: '0.5rem 0 0 0',
		fontSize: ' 1rem',
	},
	wrapperLeft: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'& img': {
			maxWidth: 330,
		},
	},
	// Table
	info: {
		backgroundColor: '#eaeaea',
		padding: '1.5rem 0 1rem 0',
		textAlign: 'center',
	},
	button: {
		fontSize: '10px',
		background: theme.palette.primary.main,
		'&:hover': {
			background: theme.palette.primary.light,
		},
	},
	buttonOrange: {
		background: theme.palette.warning.light,
		'&:hover': {
			background: theme.palette.warning.main,
		},
	},
	datagrid: {
		'& .header': {
			color: '#fff',
			fontWeight: 500,
		},
		'& .purple': {
			backgroundColor: '#a25dcb',
		},
		'& .blue': {
			backgroundColor: '#5d6ed2',
		},
		[`& .${gridClasses.row}.even`]: {
			backgroundColor: '#f1e9f6',
			'&:hover, &.Mui-hovered': {
				backgroundColor: '#edd9f9',
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
			'&.Mui-selected': {
				backgroundColor: alpha('#f1e9f6', ODD_OPACITY + theme.palette.action.selectedOpacity),
				'&:hover, &.Mui-hovered': {
					backgroundColor: alpha(
						'#f1e9f6',
						ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
					),
					// Reset on touch devices, it doesn't add specificity
					'@media (hover: none)': {
						backgroundColor: alpha('#f1e9f6', ODD_OPACITY + theme.palette.action.selectedOpacity),
					},
				},
			},
		},
	},
	row: {
		margin: 10,
		padding: '0 0.75rem',
	},
	apnTitle: {
		fontWeight: 600,
		color: '#5f6fcb',
		fontSize: 23,
	},
	column: {
		width: '100%',
	},
	buttonsWrapper: {
		display: 'grid',
		width: '100%',
		gridTemplateColumns: '1fr 1fr 1fr',
		gridColumnGap: 8,
		'& img': {
			height: 80,
		},
	},
	containerUpload: {
		display: 'flex',
		width: '100%',
		margin: '0 auto',
		maxWidth: 1280,
		flexDirection: 'column',
	},
	wrapperInput: {
		backgroundColor: '#5f6fcb',
		borderRadius: '20px',
		padding: '2rem',
		display: 'grid',
		gridRowGap: '1rem',
	},
	wrapperButton: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: '2rem' },
}));
