import { Button, CircularProgress } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import * as XLSX from 'xlsx';
import { sxStylesCarga, sxStylesPick, useStyles } from './styles';

const LoadFile: FC = () => {
	const classes = useStyles();
	const [load, setLoad] = useState(false);
	const [data, setData] = useState<any[]>([]);
	const [file, setFile] = useState<File | null>(null);

	const transFile = async (filex: File) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsArrayBuffer(filex!);
			fileReader.onload = (e) => {
				if (e.target) {
					const bufferArray = e.target.result;
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];
					const data = XLSX.utils.sheet_to_json(ws);
					resolve(data);
				}
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
		await promise.then((d) => {
			setData(d as any[]);
		});
	};

	const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event?.target?.files && event.target.files[0]) {
			let file_aux: File = event.target.files[0];
			await transFile(file_aux);
			setFile(file_aux);
		}
		event.target.value = '';
	};

	const handleUpFile = async () => {
		setLoad(true);
		if (!file || !data.length) return;
		try {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const payload = {
				data,
			};
			console.log(data);
			// Empatar endpoint aca
			// await useAxios.post('/1000pagos/contracargo/up/lote', payload);
			setLoad(false);
			setFile(null);
			setData([]);
		} catch (error: any) {
			console.log(error);
			setFile(null);
			setLoad(false);
		}
	};

	return (
		<div className={classes.wrapper}>
			<p className={classes.title}>Carregar arquivo</p>
			<Button size='small' sx={sxStylesPick} variant={'contained'} component='label'>
				Seleccionar Archivo
				<input type='file' accept='.xlsx, .xls, .csv' hidden onChange={handleFile} />
			</Button>
			<Button
				sx={sxStylesCarga}
				size='small'
				className={classes.loadButton}
				variant='contained'
				onClick={handleUpFile}
				disabled={file ? false : true}>
				Cargar
			</Button>
			{load && <CircularProgress className={classes.loading} />}
		</div>
	);
};

export default LoadFile;
