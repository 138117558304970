import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import doc3 from '../../../images/downloads/contratos/AtacadoAlimentar.docx';
import doc4 from '../../../images/downloads/contratos/AtacadoFarma.docx';
import doc2 from '../../../images/downloads/contratos/DEC.docx';
import doc1 from '../../../images/downloads/contratos/DistribuidorAlimentar.docx';
import RTM1 from '../../../images/nationalModel/image.jpg';

const NationalModel: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img={RTM1} title='NATIONAL MODEL' useMap='#image-map-nationalModel' />
			<map name='image-map-nationalModel'>
				<area
					target='_blank'
					alt='Distribuidor Alimentar'
					title='Distribuidor Alimentar'
					href={doc1}
					coords='390,1641,647,1709'
					shape='rect'
				/>
				<area target='_blank' alt='DEC' title='DEC' href={doc2} coords='686,1641,936,1707' shape='rect' />
				<area
					target='_blank'
					alt='Atacado Alimentar'
					title='Atacado Alimentar'
					href={doc3}
					coords='978,1643,1232,1705'
					shape='rect'
				/>
				<area
					target='_blank'
					alt='Atacado Farma'
					title='Atacado Farma'
					href={doc4}
					coords='1271,1642,1525,1709'
					shape='rect'
				/>
			</map>
		</>
	);
};

export default NationalModel;
