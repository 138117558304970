export const postRegister = `/register`;
export const getAllUsers = `/user/getAll`;
export const postResendPassword = `/user/resendPassword`;
export const getUserToken = `/user/token`;
export const putUserUpdate = `/user/update`;
export const gestLojasData = `/gestao_lojas`;
export const gestLojasParams = `/gestao_lojas/parameters`;
export const postUpdateUserPersonalization = `/user/update/info`;
export const paredeAzulLastTable = `/parede_azul`;
export const topRedesTables = `/top_redes`;
export const postUpdateUserPassword = `/user/update/password`;
export const postAdicionarLojas = `/lojas/add`;
export const postExcluirLojas = `/lojas/delete`;
export const getParamsApp = `/parametersApp`;
export const getChangeAddLojas = `/parametersApp/ADD_LOJAS`;
export const getExlusionLojas = `/exclusionLojas`;
export const postExecuteExclusion = `/exclusionLojas/execute`;
export const getLogUpload = `/getLogUpload`;
export const postUploadTabla = `/upload`;
