import { Paper } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import Loading from 'components/LoadingBar';
import { CustomColumnMenu } from 'functions';
import { rowHeight } from 'pages/GestLojas/styles';
import { FC } from 'react';
import { useStyles } from '../styles';

export interface ITwT {
	title: string;
	rows: any[];
	columns: GridColDef[];
	loading: boolean;
}

const TitlewithTable: FC<ITwT> = ({ title, rows, columns, loading }) => {
	const classes = useStyles();

	const customToolbar: () => JSX.Element = () => {
		return (
			<GridToolbarContainer className={classes.tableHeader}>
				<GridToolbarFilterButton sx={{ color: '#fff' }} />
			</GridToolbarContainer>
		);
	};
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>{title}</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.container}>
				{loading ? (
					<Loading />
				) : (
					<Paper
						sx={{ width: '-webkit-fill-available', minHeight: '75vh' }}
						elevation={3}
						className={classes.datagrid}>
						<DataGrid
							slots={{
								toolbar: customToolbar,
								columnMenu: CustomColumnMenu,
							}}
							rows={rows}
							columns={columns}
							disableColumnMenu
							// disableColumnSelector
							getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even')}
							rowHeight={rowHeight}
							columnHeaderHeight={rowHeight}
						/>
					</Paper>
				)}
			</div>
		</>
	);
};

export default TitlewithTable;
