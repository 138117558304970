import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const ParedeAzul = lazy(() => import('./ParedeAzul'));

const LazyParedeAzul = () => {
	return (
		<Suspense fallback={<Loading />}>
			<ParedeAzul />
		</Suspense>
	);
};

export default LazyParedeAzul;
