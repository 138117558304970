/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import MainMenu from 'components/AppBar';
import Loading from 'components/LoadingBar';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { clearAuth, setAuth } from 'redux/Auth/authSlice';
import { RootState } from 'redux/Store';
import { isPrivate } from './Utils';
import ProtectedRoutes from './Utils/ProtectecRoutes';
import Private from './routes/Private';
import Public from './routes/Public';
import { baseURL, home } from './routes/Urls';

export const Router: FC = () => {
	const [checking, setChecking] = useState<boolean>(true);
	const dispatch = useDispatch();
	let location = window.location.pathname;
	const { token, user, data } = useSelector((state: RootState) => state.auth);
	const tokenLocal = localStorage.getItem('token');
	const SEAux = sessionStorage.getItem('userData');

	useMemo(() => {
		if (isPrivate()) {
			const ruta = Private.find((val) => val.path === location);
			if (ruta && ruta.admin && data?.perfil !== 'Admin') {
				window.location.replace(home);
			}
		}
	}, [location]);

	useMemo(() => {
		if (SEAux === null || tokenLocal === null) {
			localStorage.removeItem('token');
			dispatch(clearAuth());
		}
		if (SEAux !== null) {
			const data = JSON.parse(SEAux);
			dispatch(setAuth({ token: data.token, user: true, data }));
		}
		setChecking(false);
	}, [SEAux, tokenLocal]);

	if (checking) {
		return <Loading />;
	}

	return (
		<BrowserRouter>
			<div className='min-w-full min-h-full bg-[#E9E9E9]'>
				{!user ? (
					<Routes>
						{Public.map(({ path, element }, i) => {
							return <Route key={i} path={path} element={element} />;
						})}
						<Route path='*' element={<Navigate replace to={baseURL} />} />
					</Routes>
				) : (
					<Routes>
						<Route path={baseURL} element={<ProtectedRoutes user={user} />}>
							<Route path='/' element={<Navigate replace to={home} />} />
							{Private.map(({ path, element }, i) => (
								<Route
									key={i}
									path={path}
									element={
										<>
											<MainMenu />
											<div style={{ paddingTop: 112 }}>{element}</div>
										</>
									}
								/>
							))}
							<Route path='*' element={<Navigate replace to={home} />} />
						</Route>
					</Routes>
				)}
			</div>
			<footer style={{ textAlign: 'right', padding: '0 20px' }}>
				<span style={{ fontSize: 12, color: '#1b3271', cursor: 'pointer' }}>Política de Privacidade</span>
			</footer>
		</BrowserRouter>
	);
};

export default Router;
