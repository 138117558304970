import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const GestaoUser = lazy(() => import('./GestaoUser'));

const LazyGestaoUser = () => {
	return (
		<Suspense fallback={<Loading />}>
			<GestaoUser />
		</Suspense>
	);
};

export default LazyGestaoUser;
