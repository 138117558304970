/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useAxios from 'config';
import { createContext, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { ICanal, ICategory, IFilter, IFilters, IFrom, IMSLContext, IProduct, ISubcategory } from './interfaces';

interface Props {
	children: ReactNode;
}

const MSLContext = createContext<IMSLContext>({
	Productos: [],
	ProductosFilt: [],
	loading: false,
	category: [],
	subcategory: [],
	canal: [],
	filter: { from: undefined, value: undefined },
	handleSelectFilter: () => undefined,
	handleFilterProducts: () => undefined,
	handleProductList: () => undefined,
});
export const categoryStatic: ICategory[] = ['BODY', 'FACE', 'SUN', 'LIP', 'DEO', 'MEN', 'INTIMO', 'BATH'];
export const subcategoryStatic: ISubcategory[] = [
	'LOÇÃO',
	'CREME',
	'LIMPEZA',
	'TRATAMENTO',
	'PROTETOR FACIAL',
	'COLOR',
	'CARE',
	'DEO MASC',
	'DEO FEM',
	'BARBEAR',
	'INTIMO',
	'POS BARBA',
	'SHOWER',
	'SOAP',
	'DEO AERO',
	'DEO ROLLON',
];
const canalStatic: IFilter[] = [
	{ name: 'SUPER GRANDE', number: 0 },
	{ name: 'SUPER PEQUENO', number: 0 },
	{ name: 'FARMÁCIA', number: 0 },
	{ name: 'PERFUMARIA', number: 0 },
	{ name: 'AS INDEPENDENTE', number: 0 },
	{ name: 'TOP 15 INDIRETO ALIMENTAR', number: 0 },
	{ name: 'TOP 15 INDIRETO FARMA', number: 0 },
];

export const MSLContextProvider = ({ children }: Props) => {
	const [Productos, setProductos] = useState<IProduct[]>([]);
	const [loading, setloading] = useState(false);
	const [ProductosFilt, setProductosFilt] = useState<IProduct[]>(Productos);
	const [filter, setFilter] = useState<IFilters>({ from: undefined, value: undefined });
	const [category, setcategory] = useState<ICategory[]>(categoryStatic);
	const [subcategory, setsubcategory] = useState<ISubcategory[]>(subcategoryStatic);
	const [canal, setcanal] = useState<IFilter[]>(canalStatic);
	const [category1, setcategory1] = useState<IProduct[]>([]);
	const [category2, setcategory2] = useState<IProduct[]>([]);
	const [category3, setcategory3] = useState<IProduct[]>([]);
	const [category4, setcategory4] = useState<IProduct[]>([]);
	const [category5, setcategory5] = useState<IProduct[]>([]);
	const [category6, setcategory6] = useState<IProduct[]>([]);
	const [category7, setcategory7] = useState<IProduct[]>([]);
	const [category8, setcategory8] = useState<IProduct[]>([]);

	const handleSelectFilter = (value: number, from: IFrom) => {
		setFilter({
			from: from === filter.from ? undefined : from,
			value: value === filter.value ? undefined : value,
		});
	};

	const handleFilterProducts = (value: number, from: IFrom) => {
		setcategory(categoryStatic);
		setsubcategory(subcategoryStatic);
		setcanal(canalStatic);
		setProductosFilt(Productos);
		arrangeProducts(Productos);
		arrangeCanal(Productos);
		if (value === 100 && from === filter.from) {
			setFilter({
				from: undefined,
				value: undefined,
			});
		} else {
			setFilter({
				value,
				from,
			});
			let nameFilt: ICategory | ISubcategory | ICanal | `` = ``;
			switch (from) {
				case 'categoria':
					nameFilt = categoryStatic[value];
					setcategory([nameFilt]);
					break;
				case 'subcategoria':
					nameFilt = subcategoryStatic[value];
					filterSubCategory(nameFilt);
					break;
				case 'canal':
					nameFilt = canalStatic[value].name;
					filterCanal(nameFilt);
					break;
				default:
					setcategory(categoryStatic);
					break;
			}
		}
	};

	const filterCanal = (name: string) => {
		//Body
		setcategory1(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[0] && prod.canal === name),
		);
		//Face
		setcategory2(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[1] && prod.canal === name),
		);
		//Sun
		setcategory3(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[2] && prod.canal === name),
		);
		//Lip
		setcategory4(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[3] && prod.canal === name),
		);
		//Deo
		setcategory5(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[4] && prod.canal === name),
		);
		//Men
		setcategory6(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[5] && prod.canal === name),
		);
		//Intimo
		setcategory7(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[6] && prod.canal === name),
		);
		//Bath
		setcategory8(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[7] && prod.canal === name),
		);
	};

	const filterSubCategory = (name: string) => {
		//Body
		setcategory1(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[0] && prod.subcategoria === name),
		);
		//Face
		setcategory2(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[1] && prod.subcategoria === name),
		);
		//Sun
		setcategory3(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[2] && prod.subcategoria === name),
		);
		//Lip
		setcategory4(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[3] && prod.subcategoria === name),
		);
		//Deo
		setcategory5(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[4] && prod.subcategoria === name),
		);
		//Men
		setcategory6(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[5] && prod.subcategoria === name),
		);
		//Intimo
		setcategory7(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[6] && prod.subcategoria === name),
		);
		//Bath
		setcategory8(
			Productos.filter((prod: IProduct) => prod.categoria === categoryStatic[7] && prod.subcategoria === name),
		);
	};

	const arrangeProducts = (products: IProduct[]) => {
		//Body
		setcategory1(products.filter((prod: IProduct) => prod.categoria === categoryStatic[0]));
		//Face
		setcategory2(products.filter((prod: IProduct) => prod.categoria === categoryStatic[1]));
		//Sun
		setcategory3(products.filter((prod: IProduct) => prod.categoria === categoryStatic[2]));
		//Lip
		setcategory4(products.filter((prod: IProduct) => prod.categoria === categoryStatic[3]));
		//Deo
		setcategory5(products.filter((prod: IProduct) => prod.categoria === categoryStatic[4]));
		//Men
		setcategory6(products.filter((prod: IProduct) => prod.categoria === categoryStatic[5]));
		//Intimo
		setcategory7(products.filter((prod: IProduct) => prod.categoria === categoryStatic[6]));
		//Bath
		setcategory8(products.filter((prod: IProduct) => prod.categoria === categoryStatic[7]));
	};

	const handleProductList = (category: ICategory) => {
		switch (category) {
			case 'BODY':
				return category1;
			case 'FACE':
				return category2;
			case 'SUN':
				return category3;
			case 'LIP':
				return category4;
			case 'DEO':
				return category5;
			case 'MEN':
				return category6;
			case 'INTIMO':
				return category7;
			case 'BATH':
				return category8;
			default:
				return category1;
		}
	};

	const arrangeCanal = (products: IProduct[]) => {
		const sg = products.filter((prod) => prod.canal === canalStatic[0].name).length;
		const sp = products.filter((prod) => prod.canal === canalStatic[1].name).length;
		const fm = products.filter((prod) => prod.canal === canalStatic[2].name).length;
		const pf = products.filter((prod) => prod.canal === canalStatic[3].name).length;
		const ai = products.filter((prod) => prod.canal === canalStatic[4].name).length;
		const ia = products.filter((prod) => prod.canal === canalStatic[5].name).length;
		const iff = products.filter((prod) => prod.canal === canalStatic[6].name).length;
		const arr: IFilter[] = [
			{ name: canalStatic[0].name, number: sg },
			{ name: canalStatic[1].name, number: sp },
			{ name: canalStatic[2].name, number: fm },
			{ name: canalStatic[3].name, number: pf },
			{ name: canalStatic[4].name, number: ai },
			{ name: canalStatic[5].name, number: ia },
			{ name: canalStatic[6].name, number: iff },
		];
		setcanal(arr);
	};

	const getProductos = async () => {
		const resp = await useAxios.get(`/mslProducts`).then((resp) => resp.data);
		setProductos(resp);
		arrangeProducts(resp);
		arrangeCanal(resp);
	};

	useEffect(() => {
		if (!filter.from) {
			setcategory(categoryStatic);
			arrangeProducts(Productos);
			arrangeCanal(Productos);
		}
	}, [category]);

	useLayoutEffect(() => {
		getProductos();
	}, []);

	return (
		<MSLContext.Provider
			value={{
				Productos,
				loading,
				ProductosFilt,
				filter,
				category,
				subcategory,
				canal,
				handleSelectFilter,
				handleFilterProducts,
				handleProductList,
			}}>
			{children}
		</MSLContext.Provider>
	);
};

export default MSLContext;
