import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const Top15 = lazy(() => import('./Top15'));

const LazyTop15 = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Top15 />
		</Suspense>
	);
};

export default LazyTop15;
