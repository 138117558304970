/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	AppBar,
	Box,
	Button,
	ClickAwayListener,
	Divider,
	Drawer,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Modal,
	Tab,
	Tabs,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import classNames from 'classnames';
import { handleError } from 'components/Alert';
import useAxios from 'config';
import { postUpdateUserPassword, postUpdateUserPersonalization } from 'config/endpoints';
import { sortArray } from 'functions';
import NiveaLogo from 'images/logo-menu.png';
import { ILink } from 'interfaces';
import React, { FC, MouseEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearAuth } from 'redux/Auth/authSlice';
import { RootState } from 'redux/Store';
import * as url from 'router/routes/Urls';
import { baseURL } from 'router/routes/Urls';
import Swal from 'sweetalert2';
import {
	ApnItems,
	GestãoDistItems,
	GestãoItemsLojas,
	IMenuItems,
	ProcIndiItems,
	ProgramaItems,
	auxLink,
} from './Tabs';
import { drawerWidth, useStyles } from './styles';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const MainMenu: FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// selector que te trae si es admin o no
	const { token, user, data } = useSelector((state: RootState) => state.auth);
	const [isAdmin, setisAdmin] = useState(false);
	const [open, setOpen] = useState<boolean>(false);
	const [links, setlinks] = useState<ILink[]>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
	const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
	const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
	const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);
	const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const openMenu1 = Boolean(anchorEl1);
	const openMenu2 = Boolean(anchorEl2);
	const openMenu3 = Boolean(anchorEl3);
	const openMenu4 = Boolean(anchorEl4);
	const openMenu5 = Boolean(anchorEl5);
	const [formData, setFormData] = useState({
		id: '',
		name: '',
		username: '',
		phone: '',
	});
	const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	useLayoutEffect(() => {
		const orderList = sortArray(auxLink, 'name');
		setlinks(orderList);
	}, []);

	const handleLogout = () => {
		localStorage.removeItem('token');
		dispatch(clearAuth());
		navigate(baseURL);
		sessionStorage.clear();
	};

	const handleClick = (event: MouseEvent<HTMLElement>, menu: number) => {
		switch (menu) {
			case 0:
				setAnchorEl(event.currentTarget);
				break;
			case 1:
				setAnchorEl1(event.currentTarget);
				break;
			case 2:
				setAnchorEl2(event.currentTarget);
				break;
			case 3:
				setAnchorEl3(event.currentTarget);
				break;
			case 4:
				setAnchorEl4(event.currentTarget);
				break;
			case 5:
				setAnchorEl5(event.currentTarget);
				break;
			default:
				break;
		}
	};

	const handleClose = () => setAnchorEl(null);
	const handleClose1 = () => setAnchorEl1(null);
	const handleClose2 = () => setAnchorEl2(null);
	const handleClose3 = () => setAnchorEl3(null);
	const handleClose4 = () => setAnchorEl4(null);
	const handleClose5 = () => setAnchorEl5(null);

	const handleClickAway = () => {
		handleClose();
		handleClose1();
		handleClose2();
		handleClose3();
		handleClose4();
		handleClose5();
		handleDrawerClose();
	};

	const [openModal, setOpenModal] = React.useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const styleModalUserUpdate = {
		position: 'absolute' as 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 900,
		bgcolor: 'background.paper',
		border: '2px solid #ccc',
		borderRadius: '20px',
		boxShadow: 24,
		p: 4,
	};

	useMemo(() => {
		if (userData.data) setisAdmin(userData.data.perfil === 'Admin');
		else setisAdmin(false);
	}, [data]);

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		// console.log('ejecutando user effect');
		setFormData({
			id: userData?.data?.id,
			name: userData?.data?.name,
			username: userData?.data?.username,
			phone: userData?.data?.phone,
		});
	}, []);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSaveCreateUser = async () => {
		try {
			Swal.fire({
				title: `Atualizar as informações pessoais do usuário?`,
				icon: 'warning',
				confirmButtonText: 'De acordo',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					Swal.fire({
						icon: 'info',
						title: 'Atualizando...',
						showConfirmButton: false,
						customClass: { container: 'swal2-validated' },
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					const res = await useAxios
						.post(postUpdateUserPersonalization, {
							id: formData.id,
							name: formData.name,
							username: formData.username,
							phone: formData.phone,
						})
						.then((resp) => resp.data);
					// if(res.){} //Condicion para mostrar exito
					Swal.hideLoading();
					Swal.fire({
						icon: 'success',
						title: 'Usuário atualizado',
						confirmButtonText: 'Ok',
						timer: 2500,
					});
				}
			});
		} catch (error) {
			handleError(error);
		}
	};

	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = () => {
		if (newPassword !== confirmPassword) {
			setErrorMessage('As senhas não correspondem');
			return;
		}

		// Enviar datos a un endpoint
		const data = {
			id: userData.data.id,
			oldPassword: oldPassword,
			newPassword: newPassword,
			username: userData.data.username,
		};

		function clearFormPassword() {
			setOldPassword('');
			setNewPassword('');
			setConfirmPassword('');
			setErrorMessage('');
			setShowPassword(false);
		}

		try {
			Swal.fire({
				title: `Confirme que deseja alterar a senha?`,
				icon: 'warning',
				confirmButtonText: 'De acordo',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					Swal.fire({
						icon: 'info',
						title: 'Atualizando...',
						showConfirmButton: false,
						customClass: { container: 'swal2-validated' },
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					const res = await useAxios.post(postUpdateUserPassword, data).then((resp) => {
						if (resp.status === 200) {
							console.log(resp);
							if (resp.data && resp.data.message) {
								// Alerta para éxito con mensaje
								if (resp.data.message === 'A senha antiga não está correta') {
									Swal.hideLoading();
									Swal.fire({
										icon: 'error',
										title: resp.data.message,
										confirmButtonText: 'Ok',
										timer: 2500,
									});
								} else {
									Swal.hideLoading();
									Swal.fire({
										icon: 'success',
										title: resp.data.message,
										confirmButtonText: 'Ok',
										timer: 2500,
									});

									clearFormPassword();
								}
							} else {
								// Alerta para éxito sin mensaje
								Swal.hideLoading();
								Swal.fire({
									icon: 'success',
									title: 'A operação foi concluída com sucesso.',
									confirmButtonText: 'Ok',
									timer: 2500,
								});
								clearFormPassword();
							}
						} else if (resp.status === 500) {
							if (resp.data && resp.data.error) {
								// Alerta para error con mensaje
								Swal.hideLoading();
								Swal.fire({
									icon: 'error',
									title: resp.data.error,
									confirmButtonText: 'Ok',
									timer: 2500,
								});
							} else {
								// Alerta para error sin mensaje
								Swal.hideLoading();
								Swal.fire({
									icon: 'error',
									title: 'Ocorreu um erro no servidor.',
									confirmButtonText: 'Ok',
									timer: 2500,
								});
							}
						}
					});
				}
			});
		} catch (error) {
			handleError(error);
		}
	};

	return (
		<>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={classes.root}>
					<AppBar
						position='fixed'
						className={classNames(classes.appBar, {
							[classes.appBarShift]: open,
						})}>
						<Toolbar>
							<div
								className={classes.img}
								onClick={process.env.REACT_APP_ENV === 'dev' ? () => handleDrawerOpen() : undefined}>
								<img src={NiveaLogo} alt='logo nivea' />
							</div>
							<div onClick={handleOpenModal} className={classes.boxPerson}>
								<AccountCircleIcon sx={{ fontSize: '1.5rem', marginRight: '0.5rem' }}></AccountCircleIcon>
								{userData.data?.perfil && <span>Bem-vindo: {userData.data.name}</span>}
							</div>
						</Toolbar>
						<Modal
							open={openModal}
							onClose={handleCloseModal}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'>
							<Box sx={styleModalUserUpdate}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<div className={classes.boxProfile}>
										<AccountCircleIcon sx={{ fontSize: '1.5rem', marginRight: '0.5rem' }}></AccountCircleIcon>
										{userData.data?.perfil && <h2>Perfil: {userData.data.perfil}</h2>}
									</div>

									<hr></hr>
									<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
										<Tab label='Meus dados' {...a11yProps(0)} />
										<Tab label='Trocar senha' {...a11yProps(1)} />
										<Tab label='Suporte' {...a11yProps(2)} />
									</Tabs>
								</Box>
								<CustomTabPanel value={value} index={0}>
									<form>
										<TextField
											sx={{ width: '100%', marginBottom: '1rem' }}
											id='outlined-email-input'
											label='Mail (Somente leitura)'
											type='email'
											aria-label='Email'
											name='username'
											value={formData.username}
											onChange={handleInputChange}
											inputProps={{ readOnly: true }}
										/>

										<TextField
											sx={{ width: '100%', marginBottom: '1rem' }}
											id='outlined-email-input'
											label='Nome'
											type='text'
											aria-label='name'
											name='name'
											value={formData.name}
											onChange={handleInputChange}
										/>

										<TextField
											sx={{ width: '100%', marginBottom: '1rem' }}
											id='outlined-email-input'
											label='Celular'
											type='text'
											aria-label='Celular'
											value={formData.phone}
											name='phone'
											onChange={handleInputChange}
										/>

										<div>
											<Button color='secondary' onClick={handleSaveCreateUser}>
												EDITAR / SALVAR
											</Button>
											<Button color='error' onClick={handleCloseModal}>
												FECHAR
											</Button>
										</div>
									</form>
								</CustomTabPanel>
								<CustomTabPanel value={value} index={1}>
									<form>
										<TextField
											sx={{ width: '100%', marginBottom: '1rem' }}
											label='Senha atual'
											type={showPassword ? 'text' : 'password'}
											value={oldPassword}
											onChange={(e) => setOldPassword(e.target.value)}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton onClick={handleShowPassword}>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										<TextField
											sx={{ width: '100%', marginBottom: '1rem' }}
											label='Nova senha'
											type={showPassword ? 'text' : 'password'}
											value={newPassword}
											onChange={(e) => setNewPassword(e.target.value)}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton onClick={handleShowPassword}>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										<TextField
											sx={{ width: '100%', marginBottom: '1rem' }}
											label='Confirmar senha'
											type={showPassword ? 'text' : 'password'}
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											error={errorMessage !== ''}
											helperText={errorMessage}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton onClick={handleShowPassword}>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										<div>
											<Button color='secondary' type='button' onClick={handleSubmit}>
												EDITAR / SALVAR
											</Button>
											<Button color='error' onClick={handleCloseModal}>
												FECHAR
											</Button>
										</div>
									</form>
								</CustomTabPanel>
								<CustomTabPanel value={value} index={2}>
									<TextField
										sx={{ width: '100%', marginBottom: '2rem' }}
										id='outlined-email-input'
										autoComplete='email'
										label='Titulo'
										type='email'
										aria-label='Email'
									/>
									<TextField
										sx={{ width: '100%', marginBottom: '1rem' }}
										id='outlined-multiline-static'
										label='Texto'
										multiline
										rows={4}
									/>
									<div>
										<Button color='secondary'>EDITAR / SALVAR</Button>
										<Button color='error' onClick={handleCloseModal}>
											FECHAR
										</Button>
									</div>
								</CustomTabPanel>
							</Box>
						</Modal>
						<List className={classes.list}>
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }}>
								<Link to={url.home}>
									<Button className={classes.li}>
										<HomeIcon sx={{ marginRight: '0.25rem' }}></HomeIcon>
										Home
									</Button>
								</Link>
							</ListItem>
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }}>
								<Button
									className={classes.li}
									aria-controls={openMenu5 ? 'demo-positioned-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openMenu5 ? 'true' : undefined}
									onClick={(e) => handleClick(e, 5)}>
									Gestão
									<KeyboardArrowDownIcon />
								</Button>
								<Menu
									id='demo-positioned-menu'
									disableScrollLock={true}
									aria-labelledby='demo-positioned-button'
									anchorEl={anchorEl5}
									open={openMenu5}
									onClose={handleClose5}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}>
									{GestãoItemsLojas.map((item: IMenuItems, i: number) => {
										if (
											userData.data.perfil === 'Lider Trade' &&
											item.admin &&
											item.name === 'Administração Lojas'
										)
											return (
												<Link to={item.url} key={i}>
													<MenuItem onClick={handleClose5}>{item.name}</MenuItem>
												</Link>
											);
										if (item.admin && !isAdmin) return undefined;
										return (
											<Link to={item.admin ? (isAdmin ? item.url : url.home) : item.url} key={i}>
												<MenuItem onClick={handleClose5}>{item.name}</MenuItem>
											</Link>
										);
									})}
								</Menu>
							</ListItem>
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }}>
								<Button
									className={classes.li}
									aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openMenu ? 'true' : undefined}
									onClick={(e) => handleClick(e, 0)}>
									Gestão Distribuidores
									<KeyboardArrowDownIcon />
								</Button>
								<Menu
									id='demo-positioned-menu'
									disableScrollLock={true}
									aria-labelledby='demo-positioned-button'
									anchorEl={anchorEl}
									open={openMenu}
									onClose={handleClose}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}>
									{GestãoDistItems.map((item, i) => (
										<Link to={item.url} key={i}>
											<MenuItem onClick={handleClose}>{item.name}</MenuItem>
										</Link>
									))}
								</Menu>
							</ListItem>
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }}>
								<Button
									className={classes.li}
									aria-controls={openMenu1 ? 'demo-positioned-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openMenu1 ? 'true' : undefined}
									onClick={(e) => handleClick(e, 1)}>
									Processos Indiretos
									<KeyboardArrowDownIcon />
								</Button>
								<Menu
									id='demo-positioned-menu1'
									disableScrollLock={true}
									aria-labelledby='demo-positioned-button'
									anchorEl={anchorEl1}
									open={openMenu1}
									onClose={handleClose1}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}>
									{ProcIndiItems.map((item, i) => (
										<Link to={item.url} key={i}>
											<MenuItem onClick={handleClose1}>{item.name}</MenuItem>
										</Link>
									))}
								</Menu>
							</ListItem>
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }}>
								<Button
									className={classes.li}
									aria-controls={openMenu2 ? 'demo-positioned-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openMenu2 ? 'true' : undefined}
									onClick={(e) => handleClick(e, 2)}>
									APN
									<KeyboardArrowDownIcon />
								</Button>
								<Menu
									id='demo-positioned-menu2'
									disableScrollLock={true}
									aria-labelledby='demo-positioned-button'
									anchorEl={anchorEl2}
									open={openMenu2}
									onClose={handleClose2}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}>
									{ApnItems.map((item, i) => (
										<Link to={item.url} key={i}>
											<MenuItem onClick={handleClose2}>{item.name}</MenuItem>
										</Link>
									))}
								</Menu>
							</ListItem>
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }}>
								<Button
									className={classes.li}
									aria-controls={openMenu2 ? 'demo-positioned-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openMenu2 ? 'true' : undefined}
									onClick={(e) => handleClick(e, 3)}>
									Programa
									<KeyboardArrowDownIcon />
								</Button>
								<Menu
									id='demo-positioned-menu2'
									disableScrollLock={true}
									aria-labelledby='demo-positioned-button'
									anchorEl={anchorEl3}
									open={openMenu3}
									onClose={handleClose3}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}>
									{ProgramaItems.map((item, i) => (
										<Link to={item.url} key={i}>
											<MenuItem onClick={handleClose3}>{item.name}</MenuItem>
										</Link>
									))}
								</Menu>
							</ListItem>
							{/* <ListItem disablePadding sx={{maxWidth: 'max-content'}}>
								<Button
									className={classes.li}
									aria-controls={openMenu2 ? 'demo-positioned-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openMenu2 ? 'true' : undefined}
									onClick={(e) => handleClick(e, 4)}>
									Desempenho
									<KeyboardArrowDownIcon />
								</Button>
								<Menu
									id='demo-positioned-menu2'
									disableScrollLock={true}
									aria-labelledby='demo-positioned-button'
									anchorEl={anchorEl4}
									open={openMenu4}
									onClose={handleClose4}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}>
									{DesempenhoItems.map((item, i) => (
										<Link to={item.url} key={i}>
											<MenuItem onClick={handleClose4}>{item.name}</MenuItem>
										</Link>
									))}
								</Menu>
							</ListItem> */}
							<ListItem disablePadding sx={{ maxWidth: 'max-content' }} style={{ gridColumn: 8 }}>
								<Button onClick={handleLogout} className={classes.li} sx={{ minWidth: 100 }}>
									Sair
								</Button>
							</ListItem>
						</List>
					</AppBar>
					<Drawer
						anchor='left'
						variant='permanent'
						onClose={handleDrawerClose}
						ModalProps={{
							keepMounted: true,
						}}
						classes={{
							paper: classNames({
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
							}),
						}}
						sx={{
							flexShrink: 0,
							[`& .MuiDrawer-paper`]: {
								width: open ? drawerWidth : 0,
								boxSizing: 'content-box',
							},
						}}>
						<div className={classes.toolbar}>
							<IconButton onClick={handleDrawerClose}>
								<ChevronLeftIcon />
							</IconButton>
						</div>
						<Divider />
						<List>
							{links.map(({ name, icon, link }, i) => (
								<Link to={link} key={i} onClick={handleDrawerClose} className={classes.link}>
									<ListItemButton>
										<ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
										<ListItemText primary={name} />
									</ListItemButton>
								</Link>
							))}
						</List>
						<Divider />
					</Drawer>
				</div>
			</ClickAwayListener>
		</>
	);
};

export default MainMenu;
