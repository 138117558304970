import TitlePicturePage from 'components/TitlePicturePage';
import img1 from 'images/restante/credito/image-1.jpg';
import { FC } from 'react';

const Credito: FC = () => {
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img={img1} title='CRÉDITO' />
		</>
	);
};

export default Credito;
