import { Theme, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gridClasses } from '@mui/x-data-grid';
const ODD_OPACITY = 0.2;
export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		padding: 25,
	},
	titleBar: {
		padding: 1,
	},
	title: {
		color: '#fff',
		margin: '0.5rem',
		textAlign: 'center',
		fontSize: '1.67rem',
	},
	separator: {
		backgroundColor: '#fff',
		margin: '1rem auto 1.5rem auto',
		width: 60,
		height: 4,
	},
	tableHeader: {
		display: 'grid',
		padding: 8,
		backgroundColor: '#5d6ed2',
		color: '#fff',
		alignItems: 'center',
		gridTemplateColumns: ' 1fr 1fr 1fr 1fr 1fr',
		justifyItems: 'center',
	},
	datagrid: {
		'& .MuiDataGrid-columnHeaders': {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
		},
		'& .header': {
			color: '#fff',
			fontWeight: 500,
		},
		'& .purple': {
			backgroundColor: '#a25dcb',
		},
		'& .blue': {
			backgroundColor: '#5d6ed2',
		},
		[`& .${gridClasses.row}.even`]: {
			backgroundColor: '#f1e9f6',
			'&:hover, &.Mui-hovered': {
				backgroundColor: '#edd9f9',
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
			'&.Mui-selected': {
				backgroundColor: alpha('#f1e9f6', ODD_OPACITY + theme.palette.action.selectedOpacity),
				'&:hover, &.Mui-hovered': {
					backgroundColor: alpha(
						'#f1e9f6',
						ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
					),
					// Reset on touch devices, it doesn't add specificity
					'@media (hover: none)': {
						backgroundColor: alpha('#f1e9f6', ODD_OPACITY + theme.palette.action.selectedOpacity),
					},
				},
			},
		},
	},
}));
