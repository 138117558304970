import { useStyles } from 'components/TitlePicturePage/styles';
import RTM1 from '../../../images/apn2022/10_competencia_rh/Slide80.jpg';
import RTM2 from '../../../images/apn2022/10_competencia_rh/Slide81.jpg';

import { FC } from 'react';
const CompRH: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>COMPETÊNCIA RH</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img' />
				<img src={RTM2} alt='img' />
			</div>
		</>
	);
};

export default CompRH;
