import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import img1 from '../../../images/restante/win-the-distribuitor/image-1.jpg';
import img2 from '../../../images/restante/win-the-distribuitor/image-2.jpg';
import img3 from '../../../images/restante/win-the-distribuitor/image-3.jpg';
import img4 from '../../../images/restante/win-the-distribuitor/image-4.jpg';

const WinDist: FC = () => {
	const img_arr = [img1, img2, img3, img4];
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img_arr={img_arr} title='WIN THE DISTRIBUTOR' />
		</>
	);
};

export default WinDist;
