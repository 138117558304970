import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const GestLojas = lazy(() => import('./GestLojas'));

const LazyGestLojas = () => {
	return (
		<Suspense fallback={<Loading />}>
			<GestLojas />
		</Suspense>
	);
};

export default LazyGestLojas;
