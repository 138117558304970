import { useStyles } from 'components/TitlePicturePage/styles';
import { FC } from 'react';
import RTM1 from '../../../images/apn2022/07_competencia_financeira/Slide59.jpg';
import RTM2 from '../../../images/apn2022/07_competencia_financeira/Slide60.jpg';
import RTM3 from '../../../images/apn2022/07_competencia_financeira/Slide61.jpg';
import RTM4 from '../../../images/apn2022/07_competencia_financeira/Slide62.jpg';
import RTM5 from '../../../images/apn2022/07_competencia_financeira/Slide63.jpg';
import RTM6 from '../../../images/apn2022/07_competencia_financeira/Slide64.jpg';
import RTM7 from '../../../images/apn2022/07_competencia_financeira/Slide65.jpg';
const CompFinanc: FC = () => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.titleBar} style={{ backgroundColor: '#3d67c5' }}>
				<p className={classes.title}>COMPETÊNCIA FINANCEIRA</p>
				<div className={classes.separator}></div>
			</div>
			<div className={classes.content} style={{ backgroundColor: '#fff' }}>
				<img src={RTM1} alt='img' />
				<img src={RTM2} alt='img' />
				<img src={RTM3} alt='img' />
				<img src={RTM4} alt='img' />
				<img src={RTM5} alt='img' />
				<img src={RTM6} alt='img' />
				<img src={RTM7} alt='img' />
			</div>
		</>
	);
};

export default CompFinanc;
