import Loading from 'components/LoadingBar';
import { Suspense, lazy } from 'react';

const UploadDados = lazy(() => import('./UploadDados'));

const LazyUploadDados = () => {
	return (
		<Suspense fallback={<Loading />}>
			<UploadDados />
		</Suspense>
	);
};

export default LazyUploadDados;
