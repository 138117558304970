import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';

interface ILoading {
	open?: boolean;
}

const Loading: FC<ILoading> = ({ open = true }) => {
	return (
		<>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
};

export default Loading;
