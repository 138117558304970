import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CustomColumnMenu } from 'functions';
import { useStyles as useStylesDef } from 'pages/GestDistribuidores/Definitions/styles';
import { rowHeight } from 'pages/GestLojas/styles';
import { FC } from 'react';
import img1 from '../../../../../images/apn/icon-search.jpg';
import { useStyles } from '../../styles';
import DescComercio from '../DescComercio';

interface IDynamicTable {
	columns: GridColDef[];
	rows: any[];
	open: boolean;
	handleCloseRow: () => void;
	row: any;
}

const DynamicTable: FC<IDynamicTable> = ({ columns, rows, open, handleCloseRow, row }) => {
	const classes = useStyles();
	const classesDef = useStylesDef();

	return (
		<>
			{/* Header */}
			{!open ? (
				<>
					<div className={classes.info}>
						<div className={classesDef.container} style={{ position: 'sticky', height: 60 }}>
							<img alt='icon' src={img1} style={{ height: 50, position: 'absolute', left: 0 }} />
							<div
								style={{
									position: 'absolute',
									left: 70,
									textAlign: 'left',
									right: 0,
									top: 0,
									bottom: 0,
									fontSize: '0.9rem',
								}}>
								<span
									style={{
										fontSize: '1.2rem',
										textTransform: 'uppercase',
									}}>
									Consulte os resultados
								</span>
								<br />
								Veja como foi a performance das planilhas APN de acordo com o sistema de avaliação.
							</div>
						</div>
					</div>
				</>
			) : (
				<div className={classes.row}>
					<Button
						onClick={handleCloseRow}
						variant='contained'
						className={classes.buttonOrange}
						sx={{ maxHeight: 48 }}>
						VOLTAR PARA LISTA
					</Button>
				</div>
			)}
			{/* Table */}
			{!open ? (
				<div className={classesDef.container}>
					<div className={classes.datagrid}>
						<DataGrid
							rows={rows}
							columns={columns}
							columnHeaderHeight={rowHeight}
							sx={{ fontSize: '14px', color: '#000' }}
							getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even')}
							rowHeight={rowHeight}
							slots={{
								columnMenu: CustomColumnMenu,
							}}
							hideFooter
						/>
					</div>
				</div>
			) : (
				<DescComercio row={row} />
			)}
		</>
	);
};

export default DynamicTable;
