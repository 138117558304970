import TitlePicturePage from 'components/TitlePicturePage';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import HomeVideoMP4 from '../../../images/downloads/videos/tutorial.mp4';
import img1 from '../../../images/restante/win-the-shopper/image-1 (1).jpg';
import img2 from '../../../images/restante/win-the-shopper/image-2.jpg';
import img3 from '../../../images/restante/win-the-shopper/image-3.jpg';

const WinShopper: FC = () => {
	const img_arr = [img1, img2];
	return (
		<>
			<TitlePicturePage colorTitle='#3d67c5' img_arr={img_arr} title='WIN THE SHOPPER' />
			<Link to={HomeVideoMP4}>
				<img src={img3} alt='alt' />
			</Link>
		</>
	);
};

export default WinShopper;
