import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const drawerWidth = 240;
export const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
		height: 'calc(100vh - 64px)',
	},
	loading: {
		marginTop: '1rem',
	},
	loadButton: {
		backgroundColor: theme.palette.primary.main,
		'& hover': {
			backgroundColor: theme.palette.primary.light,
		},
	},
	row: {},
	title: {
		color: '#123475',
		margin: '0.5rem',
		textAlign: 'center',
		fontSize: '1.67rem',
	},
}));

export const sxStylesCarga = {
	width: '120px',
	height: '40px',
	marginTop: '1rem',
} as const;

export const sxStylesPick = {
	maxWidth: '240px',
	height: '40px',
	marginTop: '1rem',
} as const;
