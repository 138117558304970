export const baseURL = `/`;
export const home = `${baseURL}home`;
export const papelDist = `${baseURL}papel-distribuidor`;
export const planoInverno = `${baseURL}plano-inverno`;
export const rotinaRCA = `${baseURL}rotina-rca`;
export const winDist = `${baseURL}win-distributor`;
export const winShopper = `${baseURL}win-shopper`;
export const rotaMercado = `${baseURL}rota-mercado`;
export const natModel = `${baseURL}national-model`;
export const procGamePlan = `${baseURL}proc-plan`;
export const definitions = `${baseURL}definicoes`;
export const procCadastro = `${baseURL}fluxo-cadastro`;
export const reqMinimos = `${baseURL}req-minimos`;
export const rankRegional = `${baseURL}ranking-regional`;
export const rankBrasil = `${baseURL}ranking-brasil`;
export const matrizAPN = `${baseURL}matrizapn`;
export const analisisInd = `${baseURL}analise-individual`;
export const compComercial = `${baseURL}comp-comercial`;
export const compFinanc = `${baseURL}comp-financeira`;
export const compExec = `${baseURL}comp-execução`;
export const CompTecnologia = `${baseURL}comp-tecnologica`;
export const CompRH = `${baseURL}comp-rh`;
export const compLogistica = `${baseURL}comp-logistica`;
export const loadFile = `${baseURL}carregamento-arquivos`;
export const msl = `${baseURL}msl`;
export const creditoUrl = `${baseURL}credito`;
export const logisticaUrl = `${baseURL}logistica`;
export const niveaLink = `${baseURL}niveaLink`;
export const evaluation2021 = `${baseURL}avaliacao-2021`;
export const evaluation2022 = `${baseURL}avaliacao-2022`;
export const gestLojas = `${baseURL}gestao-lojas`;
export const adminLojasUrl = `${baseURL}admin-lojas`;
export const gestUsuariosUrl = `${baseURL}gestao-usuarios`;
export const paramsUrl = `${baseURL}parametros`;
export const dadosUrl = `${baseURL}upload-dados`;
export const planosReq = `${baseURL}plano-acao-req2022`;
export const planosOp = `${baseURL}plano-acao-opo2022`;
export const paredeAzul = `${baseURL}parede-azul`;
export const topRedes = `${baseURL}top-redes`;
export const top15 = `${baseURL}top-15`;

// export const login = `${baseURL}login`;
