import { IRoute } from '../../interfaces';
import LazyLanding from '../../pages/Landing';
import { baseURL } from './Urls';

const Public: IRoute[] = [
	{
		path: baseURL,
		element: <LazyLanding />,
	},
];

export default Public;
