import {
	GridColumnMenu,
	GridColumnMenuProps,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';

export const sortArray = (arr: any[], prop: string) => {
	return arr.sort((a, b) => {
		if (a[`${prop}`] < b[`${prop}`]) {
			return -1;
		}
		if (a[`${prop}`] > b[`${prop}`]) {
			return 1;
		}
		return 0;
	});
};

export const onlyUnique = (value: any, index: number, self: any) => self.indexOf(value) === index;

export const validarCNPJ = (cnpj: string) => {
	// Remove any non-digit characters from the input
	cnpj = cnpj.replace(/[^\d]+/g, '');

	// Check if the input is not a string or if it doesn't have exactly 14 digits
	if (typeof cnpj !== 'string' || cnpj.length !== 14) {
		return false;
	}

	// Check if all digits are the same, which would result in an invalid CNPJ
	if (/^(\d)\1{13}$/.test(cnpj)) {
		return false;
	}

	// Validate DVs
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0, tamanho);
	let digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;

	for (let i = tamanho; i >= 1; i--) {
		soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
		if (pos < 2) pos = 9;
	}

	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado !== parseInt(digitos.charAt(0))) {
		return false;
	}

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;

	for (let i = tamanho; i >= 1; i--) {
		soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
		if (pos < 2) pos = 9;
	}

	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado !== parseInt(digitos.charAt(1))) {
		return false;
	}

	return true;
};

export const CustomToolbar = () => {
	return (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
};

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
	return (
		<GridColumnMenu
			{...props}
			slots={{
				columnMenuFilterItem: null,
				columnMenuColumnsItem: null,
			}}
		/>
	);
};

export const formatValuesMM = (num: number | string) => {
	const numeroMonto = typeof num === 'string' ? parseFloat(num) : num;
	const formattedMonto = numeroMonto.toLocaleString('es-ES', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		useGrouping: true,
	});

	return formattedMonto;
};
