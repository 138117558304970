import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { baseURL } from 'router/routes/Urls';
import { isPublic } from '.';

const ProtectedRoutes: FC<{ user: boolean }> = ({ user }) => {
	//es publica y no estoy logueado
	if (isPublic() && !user) {
		return <Navigate to={baseURL} replace />;
	}

	return <Outlet />;
};

export default ProtectedRoutes;
